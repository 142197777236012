import { useState, useEffect, useCallback } from 'react';
import currency from 'currency.js';
import { Text, Icon, Heading } from '@requity-homes/component-library';
import { FullApprovalAcceptedInfo } from '../../graphql/generated';
import { InfoPopup } from '../';
import {
  calculateMonthlyRent,
  calculateMonthlySavings,
  minimumDownPayment,
} from '@requity-homes/underwriting';
import { roundPercentage } from '@requity-homes/utils';

interface CalculatorProps {
  fullApprovalAcceptedInfo: FullApprovalAcceptedInfo;
}

export function Calculator({ fullApprovalAcceptedInfo }: CalculatorProps) {
  const [homePrice, setHomePrice] = useState(0);
  const [initialDeposit, setInitialDeposit] = useState(0);
  const [downPaymentPercent, setDownPaymentPercent] = useState(0);
  const [savingsGoalPercent, setSavingsGoalPercent] = useState(5);
  const [rangeValues, setRangeValues] = useState({ min: 2, max: 20 });

  useEffect(() => {
    if (fullApprovalAcceptedInfo) {
      const {
        fullApprovalAcceptedHomePrice,
        fullApprovalAcceptedMinimumDownPayment,
        fullApprovalSavingsGoalInPercentage,
      } = fullApprovalAcceptedInfo;

      const initialDownPaymentPercent =
        (Number(fullApprovalAcceptedMinimumDownPayment) /
          Number(fullApprovalAcceptedHomePrice)) *
        100;

      setRangeValues({
        min: +roundPercentage(initialDownPaymentPercent),
        max: Number(fullApprovalSavingsGoalInPercentage),
      });
      setSavingsGoalPercent(Number(fullApprovalSavingsGoalInPercentage));
      setHomePrice(Number(fullApprovalAcceptedHomePrice));
      setInitialDeposit(Number(fullApprovalAcceptedMinimumDownPayment));
      setDownPaymentPercent(initialDownPaymentPercent);
    }
  }, [fullApprovalAcceptedInfo]);

  const calculateRent: () => number = useCallback(() => {
    // Return default values if user has not changed with inputs
    if (
      homePrice ===
        Number(fullApprovalAcceptedInfo.fullApprovalAcceptedHomePrice) &&
      initialDeposit ===
        Number(fullApprovalAcceptedInfo.fullApprovalAcceptedMinimumDownPayment)
    ) {
      return Number(fullApprovalAcceptedInfo.fullApprovalAcceptedMonthlyRent);
    }
    return calculateMonthlyRent(homePrice, initialDeposit);
  }, [homePrice, initialDeposit, fullApprovalAcceptedInfo]);

  const calculateSavings: () => number = useCallback(() => {
    // Return default values if user has not changed with inputs
    if (
      homePrice ===
        Number(fullApprovalAcceptedInfo.fullApprovalAcceptedHomePrice) &&
      initialDeposit ===
        Number(fullApprovalAcceptedInfo.fullApprovalAcceptedMinimumDownPayment)
    ) {
      return Number(
        fullApprovalAcceptedInfo.fullApprovalAcceptedMonthlySavings,
      );
    }

    return calculateMonthlySavings(
      homePrice,
      initialDeposit,
      savingsGoalPercent,
    );
  }, [homePrice, initialDeposit, savingsGoalPercent, fullApprovalAcceptedInfo]);

  const calculatedRent = calculateRent();
  const calculatedSavings = calculateSavings();
  const monthlyTotalSum = calculatedRent + calculatedSavings;
  const rentPercent = (calculatedRent / monthlyTotalSum) * 100;
  const year1FuturePrice = 1.05 ** 1 * homePrice;
  const year2FuturePrice = 1.05 ** 2 * homePrice;
  const year3FuturePrice = 1.05 ** 3 * homePrice;

  const cumulativeSavingsYear1 = calculatedSavings * 12 + initialDeposit;
  const cumulativeSavingsYear2 = calculatedSavings * 24 + initialDeposit;
  const cumulativeSavingsYear3 = calculatedSavings * 36 + initialDeposit;

  return (
    <div className="bg-navy-light p-8 flex flex-col gap-4 rounded-lg">
      <div>
        <div className="flex justify-between items-center mb-2">
          <Text className="font-bold">Home Budget</Text>
          <input
            type="number"
            className="w-32 bg-navy-light text-navy-med border-none rounded-lg font-bold shadow-inset text-right no-spin-button"
            value={homePrice}
            onChange={(e) => {
              setHomePrice(Number(e.target.value));
              const calculatedIntialDeposit =
                (Number(e.target.value) * downPaymentPercent) / 100;
              const requiredInitialDeposit = Math.max(
                calculatedIntialDeposit,
                minimumDownPayment,
              );
              setInitialDeposit(requiredInitialDeposit);
            }}
          />
        </div>
        <input
          type="range"
          min="150000"
          max={Number(fullApprovalAcceptedInfo?.fullApprovalAcceptedHomePrice)}
          onChange={(e) => {
            setHomePrice(Number(e.target.value));
            const calculatedIntialDeposit =
              (Number(e.target.value) * downPaymentPercent) / 100;
            const requiredInitialDeposit = Math.max(
              calculatedIntialDeposit,
              minimumDownPayment,
            );
            setInitialDeposit(requiredInitialDeposit);
          }}
          value={homePrice}
          className="w-full bg-teal-light h-3 rounded-lg rangeSlider"
        />
        <div className="flex justify-between">
          <Text>$150,000</Text>
          <Text>
            {currency(fullApprovalAcceptedInfo?.fullApprovalAcceptedHomePrice, {
              precision: 0,
            }).format()}
          </Text>
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-1 items-center">
            <Text className="font-bold">Initial Deposit</Text>
            <InfoPopup>
              <div className="flex gap-4 py-4 px-6">
                <div>
                  <Icon glyph="infoCircle" className="-mt-1" />
                </div>
                <div>
                  <Text className="font-bold mb-2" useCase="small">
                    What are the initial deposit?
                  </Text>
                  <Text useCase="small">
                    Initial deposit represents the deposits you put in to start
                    the rent-to-own program. This can include any cash,
                    investments, or other assets that could be readily converted
                    into cash that you’re willing to contribute as your
                    potential down payment.
                    <br />
                    <br />
                    This goes towards your future down payment savings. The
                    higher the initial deposits, the lower your monthly
                    rent-to-own payments will be.{' '}
                  </Text>
                </div>
              </div>
            </InfoPopup>
          </div>
          <input
            type="number"
            value={Math.round(initialDeposit)}
            className="w-32 bg-navy-light text-navy-med border-none rounded-lg font-bold shadow-inset text-right no-spin-button"
            onChange={(e) => {
              setInitialDeposit(Number(e.target.value));
              const percentValue = (Number(e.target.value) / homePrice) * 100;
              setDownPaymentPercent(percentValue);
            }}
          />
        </div>
        <input
          type="range"
          min={rangeValues.min}
          max={rangeValues.max}
          step={0.1}
          value={downPaymentPercent}
          onChange={(e) => {
            const percent = Number(e.target.value);

            setDownPaymentPercent(percent);

            const actualAmount = (percent / 100) * homePrice;
            const requiredInitialDeposit = Math.max(
              actualAmount,
              minimumDownPayment,
            );
            setInitialDeposit(requiredInitialDeposit);
          }}
          className="w-full bg-teal-light h-3 rounded-lg rangeSlider"
        />
        <div className="flex justify-between">
          <Text>{rangeValues.min}%</Text>
          <Text>{rangeValues.max}%</Text>
        </div>
      </div>
      <Text className="font-bold">Your monthly payment will be</Text>
      <Heading
        level="h4"
        element="h4"
        fontFace="sans"
        className="text-coral-dark text-center font-bold"
      >
        {currency(monthlyTotalSum, {
          precision: 0,
        }).format()}
      </Heading>

      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <span className="flex gap-4 items-center">
            <span className="bg-navy-med h-3 w-3 rounded-full"></span>
            <Text>Rent</Text>
          </span>
          <Text>
            {currency(calculatedRent, {
              precision: 0,
            }).format()}
          </Text>
        </div>
        <div className="flex justify-between">
          <span className="flex gap-4 items-center">
            <span className="bg-teal-light h-3 w-3 rounded-full"></span>
            <Text>Savings</Text>
          </span>
          <div>
            <Text>
              {currency(calculatedSavings, {
                precision: 0,
              }).format()}
            </Text>
          </div>
        </div>
      </div>
      <div className="w-full h-2 bg-teal-light rounded-lg">
        <div
          className="w-3/6 h-2 bg-navy-med rounded-lg"
          style={{ width: `${rentPercent > 100 ? 100 : rentPercent}%` }}
        ></div>
      </div>
      <div className="flex gap-4">
        <InfoPopup>
          <div className="flex gap-4 py-4 justify-between px-6">
            <div>
              <Icon glyph="infoCircle" className="-mt-1" />
            </div>
            <div>
              <Text className="font-bold mb-2" useCase="small">
                What is cumulative savings
              </Text>
              <Text useCase="small">
                Cumulative savings include your initial down payment plus
                cumulative monthly savings toward the down payment.
              </Text>
            </div>
          </div>
        </InfoPopup>
        <div className="flex-1 flex flex-col gap-1 mt-0.5">
          <Text>Cumulative savings</Text>
          <div className="flex justify-between">
            <Text>Year 1</Text>
            <Text>
              {currency(cumulativeSavingsYear1, {
                precision: 0,
              }).format()}
            </Text>
          </div>
          <div className="flex justify-between">
            <Text>Year 2</Text>
            <Text>
              {currency(cumulativeSavingsYear2, {
                precision: 0,
              }).format()}
            </Text>
          </div>
          <div className="flex justify-between">
            <Text>Year 3</Text>
            <Text>
              {currency(cumulativeSavingsYear3, {
                precision: 0,
              }).format()}
            </Text>
          </div>
        </div>
      </div>

      <div className="flex gap-4">
        <InfoPopup>
          <div className="flex gap-4 py-4 justify-between px-6">
            <div>
              <Icon glyph="infoCircle" className="-mt-1" />
            </div>
            <div>
              <Text className="font-bold mb-2" useCase="small">
                What is future buy back price?
              </Text>
              <Text useCase="small">
                The future buyback price is calculated based on the duration of
                the rent-to-own program. For each additional year you rent, the
                future buyback price will increase by 5%.
              </Text>
              <Text useCase="small" className="mt-3">
                The buyback price indicated doesn&lsquo;t include maintenance
                charge back etc. If we incur any repair and maintenance costs,
                it will be added to the buyback price.
              </Text>
            </div>
          </div>
        </InfoPopup>
        <div className="flex-1 flex flex-col gap-1 mt-0.5">
          <Text>Future home buy back price</Text>
          <div className="flex justify-between">
            <Text>Year 1</Text>
            <Text>
              {currency(year1FuturePrice, {
                precision: 0,
              }).format()}
            </Text>
          </div>
          <div className="flex justify-between">
            <Text>Year 2</Text>
            <Text>
              {currency(year2FuturePrice, {
                precision: 0,
              }).format()}
            </Text>
          </div>
          <div className="flex justify-between">
            <Text>Year 3</Text>
            <Text>
              {currency(year3FuturePrice, {
                precision: 0,
              }).format()}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
