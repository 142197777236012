import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { validateFile } from '../../utils';
import { Icon, Text } from '@requity-homes/component-library';
import { FileDropzone } from '../file-dropzone/file-dropzone';
import { CustomerFileCategory } from '../../graphql/generated';

const docTypeMap = {
  NOTICE_OF_ASSESSMENT: 'Notice of Assessment',
  EMPLOYMENT_LETTER: 'Employment Letter',
  PERSONAL_TAX_RETURNS: 'Personal Tax Returns',
  RECENT_PAY_STUBS: 'Recent Pay Stubs',
  ARTICLES_OF_INCORPORATION: 'Articles of Incorporation',
  CORPORATE_FINANCIAL_STATEMENT_AND_TAX_RETURNS:
    'Corporate Financial Statement & Tax Returns',
};

interface FileWithCategory {
  file: File;
  category: string;
}

interface FileUploadDropzoneProps {
  acceptedFiles: FileWithCategory[];
  setAcceptedFiles: Dispatch<SetStateAction<FileWithCategory[]>>;
  setInvalidFilesErrMessages: Dispatch<string[]>;
  errorMessages: string[];
  category: CustomerFileCategory;
}

export function FileUploadDropzone({
  acceptedFiles,
  setAcceptedFiles,
  setInvalidFilesErrMessages,
  errorMessages,
  category,
}: FileUploadDropzoneProps) {
  const [categoryFiles, setCategoryFiles] = useState<FileWithCategory[]>([]);

  useEffect(() => {
    setCategoryFiles(
      acceptedFiles.filter((fileInfo) => fileInfo.category === category),
    );
  }, [acceptedFiles, category]);

  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4 mb-6">
      {docTypeMap[category] && <Text>{docTypeMap[category]}</Text>}
      {categoryFiles.length === 0 && (
        <FileDropzone
          validator={(file) => {
            const [isValid, errorCode, errorMessage] = validateFile(file);

            if (!isValid) {
              errorMessages.push(`${file.name} - ${errorMessage}`);
              setInvalidFilesErrMessages(errorMessages);
              return { code: errorCode, message: errorMessage };
            }

            return null;
          }}
          onChange={(files) => {
            const fileWithCaterory = files.map((file) => ({ file, category }));
            setAcceptedFiles([...acceptedFiles, ...fileWithCaterory]);
          }}
        ></FileDropzone>
      )}
      {categoryFiles.map(({ file, category: fileCategory }) => (
        <div className="flex flex-row space-x-4" key={file.name}>
          <Text className="flex-1">{file.name}</Text>
          <Icon
            glyph="xCircle"
            className={`h-5 w-5 self-center`}
            onClick={() => {
              setAcceptedFiles((prevFiles) => {
                const fileToDelete = prevFiles.find(
                  ({ file: f, category: c }) =>
                    f.name === file.name && c === fileCategory,
                );
                const index = prevFiles.indexOf(fileToDelete);

                const arr = [...prevFiles];
                arr.splice(index, 1);
                return arr;
              });
            }}
          />
        </div>
      ))}
    </div>
  );
}
