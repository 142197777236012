import { Loading as LoadingSpinner } from '@requity-homes/component-library';
import { twMerge } from 'tailwind-merge';

export function Loading({ className = '' }) {
  return (
    <div
      className={twMerge(
        'flex flex-grow items-center justify-center text-grey-3',
        className,
      )}
    >
      <LoadingSpinner className="w-8 h-8 sm:w-10 sm:h-10" />
    </div>
  );
}
