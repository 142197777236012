import { Text } from '@requity-homes/component-library';

export function FullApprovalInfo() {
  return (
    <Text useCase="small" className="hidden md:block">
      In the full approval stage, you will provide documentation that supports
      the information you provided during the pre-qualification stage. This may
      include documents like passport, bank statements, tax returns, or credit
      check consent.
    </Text>
  );
}
