import { PreQualificationIncomeTypes } from '../graphql/generated';

export const getPageNumber = (
  page = 1,
  preQualificationIncomeSource: PreQualificationIncomeTypes = {},
): string => {
  switch (true) {
    case !preQualificationIncomeSource.selfEmployed &&
      !preQualificationIncomeSource.traditionalEmployment:
      return `${page} of 5`;
    case !preQualificationIncomeSource.selfEmployed ||
      !preQualificationIncomeSource.traditionalEmployment:
      return `${page} of 6`;
    default:
      return `${page} of 7`;
  }
};
