import { useState } from 'react';
import {
  useUploadCustomerFileMutation,
  useCustomerFileUploadUrlLazyQuery,
  CustomerFilesDocument,
} from '../../graphql/generated';
import { convertBase64 } from '../encode-blob-to-base64';
import { customerFileUploadMaxSizeBase64Bytes } from '@requity-homes/utils';

export function useFileUpload(params = { refetchQueries: null }) {
  const { refetchQueries } = params;
  const [getCustomerFileUploadUrl, { error: uploadUrlError }] =
    useCustomerFileUploadUrlLazyQuery();

  const [uploadCustomerFile, { error: fileUploadError }] =
    useUploadCustomerFileMutation({
      refetchQueries: refetchQueries || [{ query: CustomerFilesDocument }],
    });

  const [isUploading, setIsUploading] = useState(false);

  const uploadFileBase64 = async (
    { file, category },
    isForCoApplicant = false,
  ) => {
    const encodedFile = await convertBase64(file);
    await uploadCustomerFile({
      variables: {
        input: {
          content: encodedFile,
          fileName: file.name,
          documentType: category,
          isForCoApplicant,
        },
      },
    });
  };

  const uploadFileViaS3 = async (
    { file, category },
    isForCoApplicant = false,
  ) => {
    const { data } = await getCustomerFileUploadUrl({
      variables: {
        fileName: file.name,
      },
    });

    const uploadData = JSON.parse(data.customerFileUploadUrl);

    const formData = new FormData();

    formData.append('Content-Type', file.type);
    Object.entries(uploadData.fields).forEach(([k, v]) => {
      formData.append(k, v as string);
    });
    formData.append('file', file); // must be the last one

    const postRes = await fetch(uploadData.url, {
      method: 'POST',
      body: formData,
    });

    if (!postRes.ok) {
      throw postRes;
    }

    await uploadCustomerFile({
      variables: {
        input: {
          stagingFileKey: uploadData.fields.key,
          fileName: file.name,
          documentType: category,
          isForCoApplicant,
        },
      },
    });
  };

  const uploadFile = async ({ file, category }, isForCoApplicant = false) => {
    setIsUploading(true);
    if (file.size > customerFileUploadMaxSizeBase64Bytes) {
      await uploadFileViaS3({ file, category }, isForCoApplicant);
    } else {
      await uploadFileBase64({ file, category }, isForCoApplicant);
    }
    setIsUploading(false);
  };

  return {
    uploadFile,
    uploading: isUploading,
    uploadUrlError,
    fileUploadError,
  };
}
