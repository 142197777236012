import { Button, Heading, Popup, Text } from '@requity-homes/component-library';

export const RemoveCoApplicantModal = ({ onClose, onDeleteButtonClick }) => {
  return (
    <Popup>
      <div className="items-center justify-center text-center">
        <Heading element="h6" level="h5" fontFace="sans">
          Are you sure?
        </Heading>
        <Text className="mt-6 mb-12">
          You will be required to restart your application.
        </Text>
        <div className="flex">
          <Button
            type="button"
            color="coral"
            className="sm:self-center sm:px-0 w-32 border-none bg-coral-medium shadow-md-dark"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            type="button"
            color="coral"
            hierarchy="secondary"
            className="sm:self-center sm:px-0 w-32 border-none shadow-md-dark"
            onClick={onDeleteButtonClick}
          >
            YES
          </Button>
        </div>
      </div>
    </Popup>
  );
};
