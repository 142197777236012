import { ReactNode } from 'react';
import { Disclosure } from '@headlessui/react';
import { Icon, CircularProgress } from '@requity-homes/component-library';

export interface InfoBoxProps {
  title: string;
  children: ReactNode;
  defaultOpen?: boolean;
  progressPercent?: number;
}

export function InfoBox({
  title,
  children,
  defaultOpen,
  progressPercent,
}: InfoBoxProps) {
  const showProgress = progressPercent !== undefined;
  return (
    <Disclosure
      as="div"
      className="flex flex-col p-4 pl-0 gap-4 pt-2"
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex font-bold text-sm text-grey-2 text-left justify-between">
            <div className="flex-1">
              <Icon
                glyph="chevronDown"
                className={`h-5 w-5 transition-transform duration-500 motion-reduce:transition-none mr-4 ${
                  open ? 'rotate-180' : ''
                }`}
              />
              <span>{title}</span>
            </div>
            {showProgress && (
              <CircularProgress
                percent={progressPercent}
                className="-mt-4 w-30"
              />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="text-xs text-grey-2 ml-9 ">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
