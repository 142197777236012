import { useState, ReactNode } from 'react';
import { Text, Icon } from '@requity-homes/component-library';

interface FaqDropdownProps {
  title: string;
  children?: ReactNode;
  useInfoCircle?: boolean;
}

export function FaqDropdown({
  title,
  children,
  useInfoCircle,
}: FaqDropdownProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const glyphIcon = useInfoCircle ? 'infoCircle' : 'questionCircle';
  return (
    <div
      onMouseEnter={() => {
        if (children) {
          setShowDropdown(true);
        }
      }}
      onMouseLeave={() => {
        setShowDropdown(false);
      }}
    >
      <div className="cursor-pointer flex gap-4 items-center">
        <div>
          <Icon glyph={glyphIcon} className="h-5 w-5" />
        </div>
        <Text className="font-sans text-sm font-bold">{title}</Text>
      </div>
      <div
        className={`ml-6 mt-2 px-3 transition-all duration-100 ease-out overflow-hidden ${
          showDropdown ? 'max-h-64 overflow-y-auto' : 'max-h-0'
        }`}
      >
        {children}
      </div>
    </div>
  );
}
