import React, { CSSProperties, useMemo } from 'react';
import { DropzoneProps, useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 10,
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#098080',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export interface FileDropzoneProps extends DropzoneProps {
  onChange?: (files: File[]) => void;
}

export function FileDropzone(props: FileDropzoneProps) {
  const { onChange, ...restProps } = props;
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ ...restProps, onDrop: (files) => onChange(files) });

  const style = useMemo(
    () =>
      ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      } as CSSProperties),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="text-grey-dark font-medium">
          <span className="text-coral-dark">Drag &#38; drop</span>, or{' '}
          <span className="text-coral-dark">click</span> to upload files
        </p>
      </div>
    </div>
  );
}
