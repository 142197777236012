import { useState, Dispatch, SetStateAction } from 'react';
import { CustomerFileCategory } from '../../graphql/generated';
import { Icon, Text } from '@requity-homes/component-library';
import { FileUploadDropzone } from './file-upload-dropzone';
import { FileWithCategory } from '../../const';
import { twMerge } from 'tailwind-merge';
import { allowedFileTypesMessage } from '../../utils';

interface FileUploadProps {
  acceptedFiles: FileWithCategory[];
  setAcceptedFiles: Dispatch<SetStateAction<FileWithCategory[]>>;
  category: CustomerFileCategory;
  buttonCls?: string;
  buttonLabel?: string;
  dropZoneWrapperCls?: string;
  disabled?: boolean;
  className?: string;
}

export function FileUpload({
  category,
  acceptedFiles,
  setAcceptedFiles,
  buttonCls,
  buttonLabel,
  dropZoneWrapperCls,
  className,
  disabled,
}: FileUploadProps) {
  const [invalidFilesErrMessages, setInvalidFilesErrMessages] = useState<
    string[]
  >([]);

  const errorMessages = [];
  return (
    <div className={twMerge(dropZoneWrapperCls, className)}>
      <FileUploadDropzone
        key={category}
        setAcceptedFiles={setAcceptedFiles}
        acceptedFiles={acceptedFiles}
        setInvalidFilesErrMessages={setInvalidFilesErrMessages}
        errorMessages={errorMessages}
        category={category as CustomerFileCategory}
        buttonCls={buttonCls}
        buttonLabel={buttonLabel}
        disabled={disabled}
      />
      <div className="flex flex-row justify-between m-0.5">
        <div className="flex justify-center">
          {invalidFilesErrMessages.map((message) => (
            <Text
              key={message}
              className="mr-2 text-center w-4/5 text-error-dark"
              useCase="legal"
            >
              {message} - {allowedFileTypesMessage}
            </Text>
          ))}
        </div>
        {!!invalidFilesErrMessages.length && (
          <div>
            <Icon
              glyph="xCircle"
              className="h-5 w-5 self-center ml-2 cursor-pointer"
              onClick={() => setInvalidFilesErrMessages([])}
            />
          </div>
        )}
      </div>
    </div>
  );
}
