import { Dispatch, SetStateAction } from 'react';
import { Text, FormCheckbox, Icon } from '@requity-homes/component-library';

type DropDownItems = {
  label: string;
  name: string;
};
export interface MultipleDropDownSelectProps {
  className?: string;
  title: string;
  items: DropDownItems[];
  openDropdown: boolean;
  setOpenDropdown: Dispatch<SetStateAction<boolean>>;
}

export function MultipleDropDownSelect({
  className = '',
  title,
  items,
  openDropdown,
  setOpenDropdown,
}: MultipleDropDownSelectProps) {
  return (
    <div className={`relative ${className}`}>
      <div
        onClick={(event) => {
          event.stopPropagation();
          setOpenDropdown((prev) => !prev);
        }}
        className={`flex justify-between shadow-inset border-grey-4 px-3 py-2.5 pr-6 hover:cursor-pointer ${
          openDropdown ? 'border-x border-t rounded-t-xl' : 'border rounded-xl'
        } `}
      >
        <Text>{title}</Text>
        <Icon
          glyph="chevronDown"
          className={`mt-1 text-grey-2 ${openDropdown ? 'rotate-180' : ''}`}
        />
      </div>
      {openDropdown && (
        <div className="flex flex-col gap-1 border-x border-b border-grey-4 p-3 -mt-1 rounded-b-xl absolute bg-textWhite w-full">
          {items.map((item) => (
            <FormCheckbox
              key={item.name}
              label={item.label}
              name={item.name}
              inline
            />
          ))}
        </div>
      )}
    </div>
  );
}
