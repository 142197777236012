import { Text } from '@requity-homes/component-library';

export const FaqOneContent = () => (
  <ul className="list-disc ml-6 flex flex-col gap-1">
    <li>
      <Text useCase="small">
        Homes needs to be move-in ready. We cannot purchase any homes that are
        not currently habitable.
      </Text>
    </li>
    <li>
      <Text useCase="small">
        Type of homes are freehold single-family homes and townhouses
      </Text>
    </li>
    <li>
      <Text useCase="small">
        Homes have to be on municipal water and sewerage
      </Text>
    </li>
    <li>
      <Text useCase="small">
        Located in areas with minimum 50,000 population
      </Text>
    </li>
    <li>
      <Text useCase="small">Homes priced between $150,000 to $600,000</Text>
    </li>
    <li>
      <Text useCase="small">Homes with major systems upgraded recently</Text>
      <ol className="pl-1 flex flex-col gap-1.5">
        <li className="flex gap-2">
          <Text useCase="small">a.</Text>
          <Text useCase="small">
            Furnace/Heat source - HVAC system is 15 years of age or less
          </Text>
        </li>
        <li className="flex gap-2">
          <Text useCase="small">b.</Text>
          <Text useCase="small">
            Air conditioning - HVAC system is 15years of age or less
          </Text>
        </li>
        <li className="flex gap-2">
          <Text useCase="small">c.</Text>
          <Text useCase="small">Water heater - 10 years of age or less</Text>
        </li>
        <li className="flex gap-2">
          <Text useCase="small">d.</Text>
          <Text useCase="small">Roof - 15 years of age or less</Text>
        </li>
      </ol>
    </li>
    <li>
      <Text useCase="small">No rural properties</Text>
    </li>
    <li>
      <Text useCase="small">No leasehold or modular homes</Text>
    </li>
    <li>
      <Text useCase="small">No condominiums or cooperative housing</Text>
    </li>
    <li>
      <Text useCase="small">
        No homes with known hazardous materials (e.g. vermiculite insulation,
        asbestos etc.)
      </Text>
    </li>
    <li>
      <Text useCase="small">No homes with structural or foundation issues</Text>
    </li>
    <li>
      <Text useCase="small">
        No homes with an existing lease in-place where the lease date is beyond
        the closing date
      </Text>
    </li>
  </ul>
);
