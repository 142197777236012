import { useState, ReactNode } from 'react';
import { Text, Icon } from '@requity-homes/component-library';

interface FaqPopupProps {
  title: string;
  children?: ReactNode;
  secondaryTitle?: string;
  disableClick?: boolean;
  useInfoCircle?: boolean;
}

export function FaqPopup({
  title,
  children,
  secondaryTitle,
  disableClick,
  useInfoCircle,
}: FaqPopupProps) {
  const [showPopup, setShowPopup] = useState(false);
  const glyphIcon = useInfoCircle ? 'infoCircle' : 'questionCircle';
  return (
    <div
      onMouseEnter={() => {
        if (children) {
          setShowPopup(true);
        }
      }}
      onMouseLeave={() => {
        setShowPopup(false);
      }}
    >
      {showPopup && (
        <div
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          className="absolute left-0 top-0 right-0 z-50 bottom-0 h-full"
          onClick={() => setShowPopup(false)}
        >
          <div
            className="absolute bg-pure-white border border-grey-5 bottom-0 left-0 right-0 w-full pb-8"
            style={{ minHeight: '30%' }}
          >
            <div className="flex gap-2 py-4 px-3 pr-6">
              <div>
                <Icon glyph={glyphIcon} className="h-5 w-5" />
              </div>
              <div className="flex flex-col gap-4">
                <Text useCase="small" className="font-bold">
                  {secondaryTitle || title}
                </Text>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${disableClick ? '' : 'cursor-pointer'} flex gap-4`}
        onClick={() => (disableClick ? null : setShowPopup(true))}
      >
        <div>
          <Icon
            glyph={disableClick ? 'exclamationCircle' : glyphIcon}
            className="h-5 w-5"
          />
        </div>
        <Text className="font-sans text-sm font-bold">{title}</Text>
      </div>
    </div>
  );
}
