import { twMerge } from 'tailwind-merge';
interface ProgressBarProps {
  className?: string;
  progress: number;
}

export function ProgressBar({ progress, className }: ProgressBarProps) {
  return (
    <div
      className={twMerge(
        `w-full bg-coral-light h-3 rounded-xl shadow-inset ${className || ''}`,
      )}
    >
      <div
        style={{ width: `${progress}%` }}
        className="w-2/4 bg-coral-medium h-3 rounded-xl shadow-inset"
      ></div>
    </div>
  );
}
