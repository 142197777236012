import { Text } from '@requity-homes/component-library';

export function PreQualificationInfo() {
  return (
    <Text useCase="small" className="hidden md:block mb-1">
      In the pre-qualification stage, you will share your desired location,
      household&lsquo;s financial details like income and debt. By the end, you
      will know your estimated budget for home shopping.
    </Text>
  );
}
