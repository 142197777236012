import Image from 'next/image';
import { Text, Icon, Link } from '@requity-homes/component-library';
import {
  DEFAULT_MEETING_LINK,
  REQUITY_HOMES_CONTACT_NUMBER,
} from '../../const';

const renderCustomerReviews = () => (
  <div className="flex flex-col mt-4">
    <div className="flex gap-4">
      <Icon glyph="star" />
      <Text className="font-sans text-sm font-bold">
        See what our clients are saying!
      </Text>
    </div>
    <div className="flex ml-16 -mt-4">
      <div className="relative h-20 w-20 mr-12 -mt-1 scale-125">
        <Link
          href="https://ca.trustpilot.com/review/requityhomes.com"
          target="_blank"
        >
          <Image
            src="/images/trustpilot.png"
            alt="TrustPilot Logo"
            layout={'fill'}
            objectFit={'contain'}
          />
        </Link>
      </div>
      <div className="relative h-20 w-20">
        <Link
          href="https://www.google.com/search?q=requity+homes+google+review&oq=requity+homes+google+review&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg80gEINDkwNGowajGoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x89d4cb6cbba74ea3:0xb751f303c338dcb6,1,,,,"
          target="_blank"
        >
          <Image
            src="/images/google-reviews-logo.png"
            alt="Google Reviews Logo"
            layout={'fill'}
            objectFit={'contain'}
          />
        </Link>
      </div>
    </div>
  </div>
);

export function AccountManagerPopup() {
  return (
    <div className="pr-6">
      <div className="flex gap-4 md:relative pt-6">
        <div className="flex flex-col gap-3">
          <div className="flex gap-4">
            <div>
              <Icon glyph="comment-2" />
            </div>
            <Text className="font-sans text-sm font-bold">
              Need assistance with your application? Contact us on{' '}
              <Link
                href={`tel:${REQUITY_HOMES_CONTACT_NUMBER}`}
                className="text-coral-medium hover:text-grey-2 no-underline"
              >
                {REQUITY_HOMES_CONTACT_NUMBER}
              </Link>{' '}
              or{' '}
              <Link
                target="_blank"
                href={DEFAULT_MEETING_LINK}
                className="text-coral-medium hover:text-grey-2 no-underline"
              >
                Book a Call
              </Link>
            </Text>
          </div>
        </div>
      </div>
      {renderCustomerReviews()}
    </div>
  );
}
