import { useState, useEffect } from 'react';
import {
  useCustomerFilesQuery,
  CustomerFileCategory,
} from '../../graphql/generated';

export function useEnableMoveInPage() {
  const [enableMoveInPage, setEnableMoveInPage] = useState(null);
  const { data } = useCustomerFilesQuery();

  useEffect(() => {
    if (data) {
      const categories =
        data?.customerFiles?.fileUploads?.map((file) => file.documentType) ||
        [];
      if (
        categories.includes(CustomerFileCategory.TenantInsuranceCertificate) &&
        categories.includes(CustomerFileCategory.NaturalGasProvider) &&
        categories.includes(CustomerFileCategory.NaturalGasProvider) &&
        categories.includes(CustomerFileCategory.WaterAndWasteServices) &&
        categories.includes(CustomerFileCategory.Electricity)
      ) {
        setEnableMoveInPage(true);
      } else {
        setEnableMoveInPage(false);
      }
    }
  }, [data, setEnableMoveInPage]);

  return enableMoveInPage;
}
