import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { validateFile } from '../../utils';
import { Icon, Text } from '@requity-homes/component-library';
import { FileDropzone } from './file-dropzone';
import { CustomerFileCategory } from '../../graphql/generated';
import { FileWithCategory } from '../../const';

interface FileUploadDropzoneProps {
  acceptedFiles: FileWithCategory[];
  setAcceptedFiles: Dispatch<SetStateAction<FileWithCategory[]>>;
  setInvalidFilesErrMessages: Dispatch<string[]>;
  errorMessages: string[];
  category: CustomerFileCategory;
  buttonCls?: string;
  buttonLabel?: string;
  disabled?: boolean;
}

export function FileUploadDropzone({
  acceptedFiles,
  setAcceptedFiles,
  setInvalidFilesErrMessages,
  errorMessages,
  category,
  buttonCls,
  buttonLabel,
  disabled,
}: FileUploadDropzoneProps) {
  const [categoryFiles, setCategoryFiles] = useState<FileWithCategory[]>([]);

  useEffect(() => {
    setCategoryFiles(
      acceptedFiles.filter((fileInfo) => fileInfo.category === category),
    );
  }, [acceptedFiles, category]);

  return (
    <div className="flex flex-col gap-3">
      {categoryFiles.map(({ file, category: fileCategory }) => (
        <div
          className="flex flex-row space-x-4 bg-grey-5 rounded p-2"
          key={file.name}
        >
          <Text className="flex-1">{file.name}</Text>
          <Icon
            glyph="xCircle"
            className={`h-5 w-5 self-center`}
            onClick={() => {
              setAcceptedFiles((prevFiles) => {
                const fileToDelete = prevFiles.find(
                  ({ file: f, category: c }) =>
                    f.name === file.name && c === fileCategory,
                );
                const index = prevFiles.indexOf(fileToDelete);

                const arr = [...prevFiles];
                arr.splice(index, 1);
                return arr;
              });
            }}
          />
        </div>
      ))}
      <FileDropzone
        validator={(file) => {
          const [isValid, errorCode, errorMessage] = validateFile(file);

          if (!isValid) {
            errorMessages.push(`${file.name} - ${errorMessage}`);
            setInvalidFilesErrMessages(errorMessages);
            return { code: errorCode, message: errorMessage };
          }

          return null;
        }}
        onChange={(files) => {
          const fileWithCategory = files.map((file) => ({ file, category }));
          setAcceptedFiles([...acceptedFiles, ...fileWithCategory]);
        }}
        acceptedFiles={acceptedFiles}
        buttonCls={buttonCls}
        buttonLabel={buttonLabel}
        disabled={disabled}
      ></FileDropzone>
    </div>
  );
}
