import { Text } from '@requity-homes/component-library';

export const FaqTwoContent = () => (
  <div className="flex flex-col gap-2 ml-2">
    <Text useCase="small">
      <strong>Step 1:</strong> Inform your realtor of your interest to make an
      offer on a home.
    </Text>
    <Text useCase="small">
      <strong>Step 2:</strong> We&apos;ll let you know if the home meets our
      criteria.
    </Text>
    <Text useCase="small">
      <strong>Step 3:</strong> Afterwards, we&apos;ll email you a draft offer
      with important details (e.g. closing date, price, conditions, deposits,
      etc.) for your approval before sending it to the listing agent or the
      seller.
    </Text>
    <Text useCase="small">
      <strong>Step 4:</strong> Once we have your consent, we&apos;ll forward the
      offer to the listing agent/seller and wait for a response, which can take
      24-48 hours, and may involve further negotiations.
    </Text>
  </div>
);
