import { Text } from '@requity-homes/component-library';

export const FaqThreeContent = () => (
  <ul className="list-disc ml-6 flex flex-col gap-1">
    <li>
      <Text useCase="small">Sold as is</Text>
    </li>
    <li>
      <Text useCase="small">Fixer-upper</Text>
    </li>
    <li>
      <Text useCase="small">Handyman special</Text>
    </li>
    <li>
      <Text useCase="small">TLC required</Text>
    </li>
    <li>
      <Text useCase="small">Not habitable</Text>
    </li>
    <li>
      <Text useCase="small">Home has no interior photos</Text>
    </li>
  </ul>
);
