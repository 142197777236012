import { AsyncHandler, Text } from '@requity-homes/component-library';
import currency from 'currency.js';

import { usePreApprovalDetailsQuery } from '../../graphql/generated';

export function PreApprovalDetails() {
  const { data, loading, error } = usePreApprovalDetailsQuery();
  const rentPercentage =
    (data?.preApprovalResults?.monthlyRent /
      data?.preApprovalResults?.monthlyRTOPayment) *
    100;

  return (
    <AsyncHandler
      loading={loading}
      error={error}
      errorMessage="We are unable to get your pre-approval results. Please refresh the page or try again later."
    >
      <div className="flex flex-col gap-2 rounded-lg bg-navy-dark text-white-pure p-4 ml-4">
        <div className="flex flex-row justify-between">
          <Text fontWeight="bold" className="text-xs md:text-base">
            Recommended home price
          </Text>
          <Text
            fontWeight="bold"
            className="text-coral-dark text-xs md:text-base"
          >
            {currency(data?.preApprovalResults?.maxHomePrice, {
              increment: 10,
              precision: 0,
            }).format()}
          </Text>
        </div>

        <div className="flex flex-row justify-between">
          <Text fontWeight="bold" className="text-xs md:text-base">
            Monthly payment
          </Text>
          <Text
            fontWeight="bold"
            className="text-coral-dark text-xs md:text-base"
          >
            {currency(data?.preApprovalResults?.monthlyRTOPayment, {
              increment: 10,
              precision: 0,
            }).format()}
          </Text>
        </div>

        <div className="bg-white-pure rounded-lg h-2 w-full mt-8 mb-4">
          <div
            className="bg-navy-med rounded-tl-lg rounded-bl-lg h-2"
            style={{ width: `${rentPercentage}%` }}
          />
        </div>

        <div className="flex flex-row justify-between">
          <div className="flex">
            <span className="rounded-full w-4 h-4 block mt-1 mr-5 bg-navy-med" />
            <Text className="text-xs md:text-base">Rent</Text>
          </div>
          <Text className="text-xs md:text-base">
            {currency(data?.preApprovalResults?.monthlyRent, {
              increment: 10,
              precision: 0,
            }).format()}
          </Text>
        </div>

        <div className="flex flex-row justify-between">
          <div className="flex">
            <span className="rounded-full w-4 h-4 block mt-1 mr-5 bg-white-pure" />
            <Text className="text-xs md:text-base">Savings</Text>
          </div>
          <Text className="text-xs md:text-base">
            {currency(data?.preApprovalResults?.monthlySavings, {
              increment: 10,
              precision: 0,
            }).format()}
          </Text>
        </div>
      </div>
    </AsyncHandler>
  );
}
