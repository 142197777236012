import React, { CSSProperties } from 'react';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { Button } from '@requity-homes/component-library';
import { FileWithCategory } from '../../const';

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
export interface FileDropzoneProps extends DropzoneProps {
  onChange?: (files: File[]) => void;
  acceptedFiles: FileWithCategory[];
  buttonCls?: string;
  buttonLabel?: string;
  disabled?: boolean;
}

export function FileDropzone(props: FileDropzoneProps) {
  const {
    onChange,
    acceptedFiles,
    buttonCls = 'sm:self-center rounded-xl border-coral-medium text-coral-medium',
    buttonLabel = 'Upload documents',
    disabled,
    ...restProps
  } = props;
  const { getRootProps, getInputProps } = useDropzone({
    ...restProps,
    onDrop: (files) => onChange(files),
  });

  return (
    <div className="container">
      <div {...getRootProps({ style: style as CSSProperties })}>
        <input {...getInputProps()} />
        <Button
          type="button"
          color="coral"
          disabled={disabled}
          hierarchy="secondary"
          className={buttonCls}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}
