import { ReactNode } from 'react';
import { Disclosure } from '@headlessui/react';

export interface PhaseDetailsProps {
  static?: boolean;
  children: ReactNode;
}

export function PhaseDetails({
  children,
  static: isStatic,
}: PhaseDetailsProps) {
  return (
    <Disclosure.Panel
      static={isStatic}
      className="flex flex-col gap-4 px-4 py-2 pl-8"
    >
      {children}
    </Disclosure.Panel>
  );
}
