import { useState, useEffect } from 'react';
import {
  CustomerFileCategory,
  useApplicationDetailsQuery,
} from '../../graphql/generated';

export function useApplicantRequiredDocuments(applicant = 'PRIMARY_APPLICANT') {
  const [requiredDocuments, setRequiredDocuments] = useState<
    CustomerFileCategory[]
  >([]);

  const { data: applicationData } = useApplicationDetailsQuery();

  useEffect(() => {
    if (applicationData?.preApprovalApplication) {
      setRequiredDocuments([
        CustomerFileCategory.Identity,
        CustomerFileCategory.Financial,
      ]);

      const currentApplicant =
        applicant === 'PRIMARY_APPLICANT'
          ? applicationData.preApprovalApplication.primaryApplicant
          : applicationData.preApprovalApplication.coApplicant;
      const incomeSources = currentApplicant?.employmentDetails || {};

      if (incomeSources.traditionalEmployment) {
        setRequiredDocuments((prev) => [
          ...prev,
          CustomerFileCategory.EmploymentLetter,
          CustomerFileCategory.RecentPayStubs,
        ]);
      }
      if (incomeSources.selfEmployed?.isBusinessIncorporated) {
        setRequiredDocuments((prev) => [
          ...prev,
          CustomerFileCategory.CorporateFinancialStatementAndTaxReturns,
          CustomerFileCategory.ArticlesOfIncorporation,
        ]);
      }
      if (
        incomeSources.selfEmployed &&
        !incomeSources.selfEmployed?.isBusinessIncorporated
      ) {
        setRequiredDocuments((prev) => [
          ...prev,
          CustomerFileCategory.PersonalTaxReturns,
        ]);
      }
      if (incomeSources.pensionIncome) {
        setRequiredDocuments((prev) => [
          ...prev,
          CustomerFileCategory.PensionIncome,
        ]);
      }
      if (incomeSources.childTaxBenefit) {
        setRequiredDocuments((prev) => [
          ...prev,
          CustomerFileCategory.ChildTaxBenefit,
        ]);
      }
      if (incomeSources.disabilityBenefit) {
        setRequiredDocuments((prev) => [
          ...prev,
          CustomerFileCategory.DisabilityBenefit,
        ]);
      }
      if (incomeSources.others) {
        setRequiredDocuments((prev) => [...prev, CustomerFileCategory.Other]);
      }

      setRequiredDocuments((prev) => [
        ...prev,
        CustomerFileCategory.NoticeOfAssessment,
      ]);
    }
  }, [applicationData, setRequiredDocuments, applicant]);

  return requiredDocuments;
}
