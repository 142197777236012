import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Link, Logo } from '@requity-homes/component-library';
import { removeTrailingSlash } from '@requity-homes/utils';
import { ShortUrlTableDataTypes } from '@requity-homes/data-types';

async function getUrl(shortUrl: string, linkType: ShortUrlTableDataTypes) {
  const url = `${removeTrailingSlash(
    process.env.NEXT_PUBLIC_AUTH_API_URL,
  )}/customer-referral-url`;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      url: shortUrl,
      linkType: linkType,
    }),
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`Error getting referral link from API for ${shortUrl}`);
    }
    const result = await response.json();
    return result.url;
  } catch (error) {
    console.log('Fetch short url error', error);
    throw error;
  }
}

export function ReferralRedirect({
  linkType,
}: {
  linkType: ShortUrlTableDataTypes;
}) {
  const router = useRouter();

  useEffect(() => {
    (async () => {
      try {
        const url = await getUrl(window.location.href, linkType);

        return (window.location.href = url);
      } catch (error) {
        console.log('Fetch short url error', error);
        return router.push(
          `/errors/unexpected-error?error-message=${encodeURIComponent(
            'The provided referral link is invalid. Please contact Requity Homes for assistance.',
          )}`,
        );
      }
    })();
  }, []);

  return (
    <div className={'flex flex-col min-h-screen'}>
      <header className="w-full px-6 py-4 text-center md:text-left absolute">
        <Link href="/">
          <Logo color="coral" className="md:max-h-12 max-h-10 w-40" />
        </Link>
      </header>

      <main
        className={
          'flex-grow max-w-screen-sm self-center flex items-center justify-center'
        }
      >
        <div className="px-8">
          <h1 className="text-2xl font-bold text-center">Please wait...</h1>
        </div>
      </main>
    </div>
  );
}
