import Script from 'next/script';
import { format } from 'date-fns';

export interface HubspotFormProps {
  formId: string;
}

export function HubspotForm({ formId }: HubspotFormProps) {
  const region = 'na1';
  const portalId = '7807202';

  return (
    <>
      <Script
        id="hubSpotFormScript"
        src="//js.hsforms.net/forms/v2.js"
        onLoad={() => {
          window.hbspt?.forms?.create({
            region,
            portalId,
            formId,
            target: '#hubSpotFormRoot',
            onFormReady: function (form) {
              const today = new Date();
              const formattedDate = format(today, 'yyyy-MM-dd');

              // Find the hidden input field and set its value
              const hiddenDateField = form.querySelector(
                'input[name="credit_report_consent_given_date"]',
              );
              if (hiddenDateField) {
                hiddenDateField.value = formattedDate;
              }
            },
          });
        }}
      />
      <div id="hubSpotFormRoot"></div>
    </>
  );
}
