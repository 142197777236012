import { CustomerFileCategory } from '../graphql/generated';

export const doctypeToPhraseMap = {
  [CustomerFileCategory.Identity]: 'Proof of Identification',
  [CustomerFileCategory.Financial]: 'Bank Statement',
  [CustomerFileCategory.EmploymentLetter]: 'Employment Letter',
  [CustomerFileCategory.RecentPayStubs]: 'Recent Pay Stubs',
  [CustomerFileCategory.ArticlesOfIncorporation]: 'Articles of Incorporation',
  [CustomerFileCategory.CorporateFinancialStatementAndTaxReturns]:
    'Corporate Financial Statement & Tax Returns',
  [CustomerFileCategory.PersonalTaxReturns]: 'Personal Tax Returns',
  [CustomerFileCategory.DisabilityBenefit]: 'Disability Benefits',
  [CustomerFileCategory.PensionIncome]: 'Pension Income',
  [CustomerFileCategory.ChildTaxBenefit]: 'Child Tax Benefits',
  [CustomerFileCategory.Other]: 'Other',
  [CustomerFileCategory.NoticeOfAssessment]: 'Notice of Assessment',
  [CustomerFileCategory.CreditCheck]: 'Credit Check',
};
