import { useEffect } from 'react';
import { Router } from 'next/router';

export interface FormikPreventProps {
  unsavedChanges: boolean;
}

export function FormikPrevent({ unsavedChanges }: FormikPreventProps) {
  useEffect(() => {
    if (unsavedChanges) {
      const routeChangeStart = () => {
        const ok = (() => {
          return confirm('Warning! You have unsaved changes.');
        })();
        if (!ok) {
          Router.events.emit('routeChangeError');
          throw 'Abort route change. Please ignore this error.';
        }
      };
      Router.events.on('routeChangeStart', routeChangeStart);

      return () => {
        Router.events.off('routeChangeStart', routeChangeStart);
      };
    }
  }, [unsavedChanges]);

  return null;
}
