import { useState } from 'react';
import { Icon } from '@requity-homes/component-library';

export function InfoPopup({ children }) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div
      className="md:relative"
      onMouseEnter={() => {
        setShowPopup(true);
      }}
      onMouseLeave={() => {
        setShowPopup(false);
      }}
    >
      {/* Pop up for Desktop */}
      {showPopup && (
        <div
          className="absolute bg-pure-white border border-grey-4 left-6 w-80 hidden md:block"
          onClick={() => setShowPopup(false)}
        >
          {children}
        </div>
      )}

      {/* Dark Overlay for Mobile */}
      {showPopup && (
        <div
          className="absolute left-0 top-0 right-0 z-50 bottom-0 h-full md:hidden"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          onClick={() => setShowPopup(false)}
        >
          <div
            className="absolute bg-pure-white left-0 bottom-0 left-0 right-0 w-full"
            style={{ minHeight: '30%' }}
          >
            {children}
          </div>
        </div>
      )}
      <div onClick={() => setShowPopup(true)}>
        <Icon glyph="infoCircle" />
      </div>
    </div>
  );
}
