import { useState, useMemo, useEffect, useCallback } from 'react';
import NextLink from 'next/link';
import { Heading, Text, Button, Icon } from '@requity-homes/component-library';
import {
  CustomerFileCategory,
  EmploymentDetails,
  File,
  DocumentStatus,
} from '../../graphql/generated';
import { doctypeToPhraseMap } from '../../utils';

interface ApplicantDocumentsStatusProps {
  givenName: string;
  familyName: string;
  customerFiles: File[];
  employmentDetails: EmploymentDetails;
  documentStatus: DocumentStatus;
  applicationDataIsLoaded: boolean;
  bankStatementSubmittedViaOpenBanking: boolean;
  creditConsentGiven: boolean;
  handleCoApplicantRemoval?: () => void;
}

export function ApplicantDocumentsStatus({
  givenName,
  familyName,
  customerFiles,
  employmentDetails,
  documentStatus,
  applicationDataIsLoaded,
  bankStatementSubmittedViaOpenBanking,
  creditConsentGiven,
  handleCoApplicantRemoval,
}: ApplicantDocumentsStatusProps) {
  const customerDocTypesSubmitted = useMemo(() => {
    return customerFiles?.map((item) => item.documentType) || [];
  }, [customerFiles]);

  const FULL_APPROVAL_STARTED_STAGES = {
    CreditCheck: 'Credit check',
    BankStatement: 'Bank statement',
    EmploymentInformation: 'Employment information',
    SelfEmploymentInformation: 'Self-employment information',
    OtherIncomeSource: 'Other income source',
    NoticeOfAssessment: 'Notice of assessment',
    IdentityVerification: 'Proof of identification',
  };

  const [stages, setStages] = useState({});

  const [activeStage, setActiveStage] = useState('');

  useEffect(() => {
    if (applicationDataIsLoaded && employmentDetails) {
      setStages({
        [FULL_APPROVAL_STARTED_STAGES.CreditCheck]: [
          CustomerFileCategory.CreditCheck,
        ],
        [FULL_APPROVAL_STARTED_STAGES.BankStatement]: [
          CustomerFileCategory.Financial,
        ],
      });

      if (employmentDetails.traditionalEmployment) {
        setStages((prev) => ({
          ...prev,
          [FULL_APPROVAL_STARTED_STAGES.EmploymentInformation]: [
            CustomerFileCategory.EmploymentLetter,
            CustomerFileCategory.RecentPayStubs,
          ],
        }));
      }
      if (employmentDetails.selfEmployed?.isBusinessIncorporated) {
        setStages((prev) => ({
          ...prev,
          [FULL_APPROVAL_STARTED_STAGES.SelfEmploymentInformation]: [
            CustomerFileCategory.CorporateFinancialStatementAndTaxReturns,
            CustomerFileCategory.ArticlesOfIncorporation,
          ],
        }));
      }
      if (
        employmentDetails.selfEmployed &&
        !employmentDetails.selfEmployed?.isBusinessIncorporated
      ) {
        setStages((prev) => ({
          ...prev,
          [FULL_APPROVAL_STARTED_STAGES.SelfEmploymentInformation]: [
            CustomerFileCategory.PersonalTaxReturns,
          ],
        }));
      }
      // Other income source
      if (employmentDetails.pensionIncome) {
        setStages((prev) => ({
          ...prev,
          [FULL_APPROVAL_STARTED_STAGES.OtherIncomeSource]: [
            CustomerFileCategory.PensionIncome,
          ],
        }));
      }
      if (employmentDetails.childTaxBenefit) {
        setStages((prev) => {
          const prevOtherIncomeDocTypes =
            prev[FULL_APPROVAL_STARTED_STAGES.OtherIncomeSource] || [];
          return {
            ...prev,
            [FULL_APPROVAL_STARTED_STAGES.OtherIncomeSource]: [
              ...prevOtherIncomeDocTypes,
              CustomerFileCategory.ChildTaxBenefit,
            ],
          };
        });
      }
      if (employmentDetails.disabilityBenefit) {
        setStages((prev) => {
          const prevOtherIncomeDocTypes =
            prev[FULL_APPROVAL_STARTED_STAGES.OtherIncomeSource] || [];
          return {
            ...prev,
            [FULL_APPROVAL_STARTED_STAGES.OtherIncomeSource]: [
              ...prevOtherIncomeDocTypes,
              CustomerFileCategory.DisabilityBenefit,
            ],
          };
        });
      }
      if (employmentDetails.others) {
        setStages((prev) => {
          const prevOtherIncomeDocTypes =
            prev[FULL_APPROVAL_STARTED_STAGES.OtherIncomeSource] || [];
          return {
            ...prev,
            [FULL_APPROVAL_STARTED_STAGES.OtherIncomeSource]: [
              ...prevOtherIncomeDocTypes,
              CustomerFileCategory.Other,
            ],
          };
        });
      }

      setStages((prev) => ({
        ...prev,
        [FULL_APPROVAL_STARTED_STAGES.NoticeOfAssessment]: [
          CustomerFileCategory.NoticeOfAssessment,
        ],
        [FULL_APPROVAL_STARTED_STAGES.IdentityVerification]: [
          CustomerFileCategory.Identity,
        ],
      }));
    }
    // Handles users who did not select any income source type
    else if (applicationDataIsLoaded && !employmentDetails) {
      setStages({
        [FULL_APPROVAL_STARTED_STAGES.IdentityVerification]: [
          CustomerFileCategory.Identity,
        ],
        [FULL_APPROVAL_STARTED_STAGES.BankStatement]: [
          CustomerFileCategory.Financial,
        ],
        [FULL_APPROVAL_STARTED_STAGES.NoticeOfAssessment]: [
          CustomerFileCategory.NoticeOfAssessment,
        ],
        [FULL_APPROVAL_STARTED_STAGES.CreditCheck]: [
          CustomerFileCategory.CreditCheck,
        ],
      });
    }
  }, [applicationDataIsLoaded, employmentDetails]);

  const allFilesUploaded = useCallback(
    (stageTitle) => {
      const requiredFilesForStage = stages[stageTitle];
      for (const item of requiredFilesForStage) {
        if (!customerDocTypesSubmitted.includes(item)) {
          return false;
        }
      }
      return true;
    },
    [customerDocTypesSubmitted, stages],
  );

  const showFilesStatus = useCallback(
    (stageTitle) => {
      const requiredFilesForStage = stages[stageTitle];
      const filesNotSubmitted = requiredFilesForStage.filter(
        (f) => !customerDocTypesSubmitted.includes(f),
      );
      return filesNotSubmitted.map((item) => (
        <Text useCase="small" className="mt-2" key={item}>
          {doctypeToPhraseMap[item]}
        </Text>
      ));
    },
    [customerDocTypesSubmitted, stages],
  );

  const documentsWithFeedbackGiven = {
    [FULL_APPROVAL_STARTED_STAGES.IdentityVerification]:
      documentStatus?.proofOfId
        ? {
            status: documentStatus.proofOfId,
            rejectionReason: documentStatus.proofOfIdRejection,
          }
        : undefined,
    [FULL_APPROVAL_STARTED_STAGES.BankStatement]: documentStatus?.bankStatement
      ? {
          status: documentStatus.bankStatement,
          rejectionReason: documentStatus.bankStatementRejection,
        }
      : undefined,
    [FULL_APPROVAL_STARTED_STAGES.EmploymentInformation]:
      documentStatus?.employmentDocuments
        ? {
            status: documentStatus.employmentDocuments,
            rejectionReason: documentStatus.employmentDocumentsRejection,
          }
        : undefined,
    [FULL_APPROVAL_STARTED_STAGES.SelfEmploymentInformation]:
      documentStatus?.selfEmploymentDocuments
        ? {
            status: documentStatus.selfEmploymentDocuments,
            rejectionReason: documentStatus.selfEmploymentDocumentsRejection,
          }
        : undefined,
    [FULL_APPROVAL_STARTED_STAGES.OtherIncomeSource]:
      documentStatus?.otherIncomeDocuments
        ? {
            status: documentStatus.otherIncomeDocuments,
            rejectionReason: documentStatus.otherIncomeDocumentsRejection,
          }
        : undefined,
    [FULL_APPROVAL_STARTED_STAGES.NoticeOfAssessment]:
      documentStatus?.noticeOfAssessment
        ? {
            status: documentStatus.noticeOfAssessment,
            rejectionReason: documentStatus.noticeOfAssessmentRejection,
          }
        : undefined,
    [FULL_APPROVAL_STARTED_STAGES.CreditCheck]: documentStatus?.creditCheck
      ? {
          status: documentStatus.creditCheck,
          rejectionReason: documentStatus.creditCheckRejection,
        }
      : undefined,
  };

  const renderFeedback = (stageTitle) => {
    const noCustomerActionNeeded =
      documentsWithFeedbackGiven[stageTitle].status === 'Accepted' ||
      documentsWithFeedbackGiven[stageTitle].status === 'In Review';
    return (
      <div className="flex gap-4" key={stageTitle}>
        {noCustomerActionNeeded ? (
          <div>
            <Icon
              glyph="checkmark"
              className="h-5 w-5 bg-green rounded-full text-textWhite text-xs"
            />
          </div>
        ) : (
          <div>
            <Icon
              glyph="exclamationCircle"
              className="h-5 w-5 text-coral-dark"
            />
          </div>
        )}
        <div className="flex-1 flex flex-col gap-1">
          <Text className="font-bold">{stageTitle}</Text>
          <div className="flex justify-between">
            {noCustomerActionNeeded ? (
              <Text className="text-green">
                {documentsWithFeedbackGiven[stageTitle].status}
              </Text>
            ) : (
              <>
                <Text className="text-red">
                  {documentsWithFeedbackGiven[stageTitle].status}
                </Text>
                <div
                  className={`text-grey-2 cursor-pointer ${
                    activeStage === stageTitle ? 'rotate-180' : 'rotate-0'
                  }`}
                  onClick={() => {
                    if (activeStage === stageTitle) {
                      setActiveStage('');
                    } else {
                      setActiveStage(stageTitle);
                    }
                  }}
                >
                  <Icon glyph="chevronDown" className="h-3 w-3" />
                </div>
              </>
            )}
          </div>
          <div
            style={{
              transition: 'max-height 0.5s ease-out',
              overflow: 'hidden',
              maxHeight: activeStage === stageTitle ? 1000 : 0,
            }}
          >
            {!noCustomerActionNeeded && (
              <Text useCase="small" className="mt-2">
                {documentsWithFeedbackGiven[stageTitle].rejectionReason}
              </Text>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBankStatementInReview = (stageTitle) => {
    return (
      <div className="flex gap-4" key={stageTitle}>
        <div>
          <Icon
            glyph="checkmark"
            className="h-5 w-5 bg-green rounded-full text-textWhite text-xs"
          />
        </div>
        <div className="flex-1 flex flex-col gap-1">
          <Text className="font-bold">{stageTitle}</Text>
          <div className="flex justify-between">
            <Text className="text-green">In Review</Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-coral-light mt-4 px-4 md:px-8 py-4 flex flex-col gap-4 rounded-xl">
      <Heading
        level="h4"
        element="h4"
        fontFace="sans"
        className="w-full text-center"
      >
        {givenName} {familyName}
      </Heading>
      <div className="flex flex-col gap-4">
        {Object.keys(stages).map((stageTitle) => {
          if (
            documentsWithFeedbackGiven[stageTitle] &&
            documentsWithFeedbackGiven[stageTitle].status !== 'Missing'
          ) {
            return renderFeedback(stageTitle);
          }

          if (
            stageTitle === FULL_APPROVAL_STARTED_STAGES.BankStatement &&
            bankStatementSubmittedViaOpenBanking
          ) {
            return renderBankStatementInReview(stageTitle);
          }

          if (
            stageTitle === FULL_APPROVAL_STARTED_STAGES.CreditCheck &&
            creditConsentGiven
          ) {
            return renderBankStatementInReview(stageTitle);
          }

          return (
            <div className="flex gap-4" key={stageTitle}>
              {allFilesUploaded(stageTitle) ? (
                <div>
                  <Icon
                    glyph="checkmark"
                    className="h-5 w-5 bg-green rounded-full text-textWhite text-xs"
                  />
                </div>
              ) : (
                <div>
                  <Icon
                    glyph="exclamationCircle"
                    className="h-5 w-5 text-coral-dark"
                  />
                </div>
              )}
              <div className="flex-1 flex flex-col gap-1">
                <Text className="font-bold">{stageTitle}</Text>
                <div className="flex justify-between">
                  {allFilesUploaded(stageTitle) ? (
                    <Text className="text-green">In Review</Text>
                  ) : (
                    <>
                      <Text className="text-red">Missing</Text>
                      <div
                        className={`text-grey-2 cursor-pointer ${
                          activeStage === stageTitle ? 'rotate-180' : 'rotate-0'
                        }`}
                        onClick={() => {
                          if (activeStage === stageTitle) {
                            setActiveStage('');
                          } else {
                            setActiveStage(stageTitle);
                          }
                        }}
                      >
                        <Icon glyph="chevronDown" className="h-3 w-3" />
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    transition: 'max-height 0.5s ease-out',
                    overflow: 'hidden',
                    maxHeight: activeStage === stageTitle ? 1000 : 0,
                  }}
                >
                  {!allFilesUploaded(stageTitle) && showFilesStatus(stageTitle)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <NextLink passHref href="/v2/documents">
        <Button
          type="button"
          color="coral"
          hierarchy="secondary"
          className="sm:self-center rounded-xl border-coral-medium text-coral-medium"
        >
          Upload documents
        </Button>
      </NextLink>

      {handleCoApplicantRemoval && (
        <div className="bg-coral-light mt-4 px-4 md:px-8 py-4 flex flex-col gap-4 rounded-xl">
          <Text className="text-center">
            If you change your mind, you can remove this co-applicant from your
            application
          </Text>
          <Button
            type="button"
            color="coral"
            hierarchy="secondary"
            className="sm:self-center rounded-xl border-coral-medium text-coral-medium"
            onClick={() => handleCoApplicantRemoval()}
          >
            Remove this co-applicant
          </Button>
        </div>
      )}
    </div>
  );
}
