import { ReactNode } from 'react';
import { Disclosure } from '@headlessui/react';

export interface PhaseOverviewProps {
  children: ReactNode;
  defaultOpen?: boolean;
}

export function PhaseOverview({ children, defaultOpen }: PhaseOverviewProps) {
  return (
    <Disclosure defaultOpen={defaultOpen} as="div" className="flex flex-col">
      {children}
    </Disclosure>
  );
}
