import {
  fileUploadAllowedMimeTypes,
  customerFileUploadMaxSizeBytes,
} from '@requity-homes/utils';
import { ErrorCode } from 'react-dropzone';

export function validateFile(file: Blob): [boolean, ErrorCode?, string?] {
  const { size, type: fileType } = file;

  if (size > customerFileUploadMaxSizeBytes) {
    return [
      false,
      ErrorCode.FileTooLarge,
      `File is too big and should be smaller than ${(
        customerFileUploadMaxSizeBytes / 1000000
      ).toFixed(1)} Mb.`,
    ];
  }

  if (!fileUploadAllowedMimeTypes.includes(fileType)) {
    return [
      false,
      ErrorCode.FileInvalidType,
      `File type is not supported. You can upload any standard document or image.
      `,
    ];
  }

  return [true];
}

export const allowedFileTypesMessage = `
  Allowed file types:  
  pdf,
  jpeg,
  png,
  bmp,
  tiff,
  svg,
  msword,
  msexcel,
  mspowerpoint,
  txt,
  rtf,
  zip
`;
