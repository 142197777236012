import { IncomeSource } from '../graphql/generated';

export const incomeSourceMap: Record<IncomeSource, string> = {
  FULL_TIME: 'Full Time Employment',
  PART_TIME: 'Part Time Employment',
  SELF_EMPLOYED: 'Self-Employed',
  PENSION: 'Pension',
  DISABILITY: 'Disability',
  ALIMONY_CHILD_SUPPORT: 'Alimony / Child Support',
  OTHER: 'Other',
};
