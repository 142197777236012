import { Disclosure } from '@headlessui/react';
import { CircleNumber, Icon } from '@requity-homes/component-library';

export interface PhaseHeadingProps {
  stepNumber: number;
  status?: 'Unavailable' | 'Complete' | 'Available' | 'Failed';
  children: string;
}

export function PhaseHeading({
  children,
  stepNumber,
  status = 'Available',
}: PhaseHeadingProps) {
  const isUnavailable = status === 'Unavailable';

  return (
    <Disclosure.Button
      className={`flex flex-row gap-4 relative ${
        isUnavailable ? 'text-grey-3' : ''
      }`}
    >
      {({ open }) => (
        <>
          <CircleNumber
            disabled={isUnavailable}
            className="inset-0 -ml-3"
            status={status}
          >
            {stepNumber.toString()}
          </CircleNumber>
          <Icon
            glyph="chevronDown"
            className={`h-3 w-3 transition-transform duration-500 motion-reduce:transition-none -mr-3 mt-1.5 ${
              open ? 'rotate-180' : ''
            }`}
          />
          <span className="font-medium">{children}</span>
        </>
      )}
    </Disclosure.Button>
  );
}
