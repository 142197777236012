import { Form, Formik, FormikConsumer } from 'formik';
import * as yup from 'yup';
import { addDays, subYears } from 'date-fns';
import { useRouter } from 'next/router';

import {
  AsyncHandler,
  Button,
  FieldSet,
  FormCheckbox,
  FormControlGroup,
  FormInput,
  FormSelect,
  Heading,
  Hint,
  Text,
} from '@requity-homes/component-library';

import {
  Province,
  useApplicantInfoQuery,
  useCreditConsentStatusQuery,
  useSubmitCreditReportConsentMutation,
  ApplicantInfoDocument,
} from '../../graphql/generated';
import { Loading } from '../loading/loading';
import { FormikPrevent } from '../formik-prevent/formik-prevent';
import { provinceMap } from '@requity-homes/utils';

interface CreditConsentFormFields {
  primaryApplicant: ApplicantConsentInfo;
  coApplicant?: { email: string };
}

interface ApplicantConsentInfo {
  currentAddress: {
    streetAddress: string;
    city: string;
    province: Province;
  };
  postalCode?: string;
  dateOfBirth?: string; // Date: yyyy-mm-dd
  consentGiven: boolean;
}

export function CreditConsentForm() {
  const { data, loading, error } = useApplicantInfoQuery({
    fetchPolicy: 'no-cache',
  });
  const {
    data: consentData,
    loading: consentLoading,
    error: consentError,
  } = useCreditConsentStatusQuery();
  const [
    submitCreditReportConsent,
    { loading: submitLoading, error: submitError, called: submitted },
  ] = useSubmitCreditReportConsentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ApplicantInfoDocument }],
  });

  const customerInfo = data?.customerInfo;
  const creditReportConsentGiven =
    consentData?.customerInfo.creditReportConsentGiven;

  const router = useRouter();

  const initialValues: CreditConsentFormFields = {
    primaryApplicant: {
      currentAddress: {
        streetAddress: undefined,
        city: undefined,
        province: '' as unknown as Province,
      },
      dateOfBirth: undefined,
      postalCode: undefined,
      consentGiven: undefined,
    },
  };

  const minBirthday = addDays(subYears(new Date(), 18), 1); // Must be 18 years old

  let validationSchema = yup.object({
    primaryApplicant: yup.object({
      currentAddress: yup.object({
        streetAddress: yup
          .string()
          .trim()
          .required('Please provide your current street address.'),
        city: yup
          .string()
          .trim()
          .required('Please provide the city you are currently living in.'),
        province: yup
          .string()
          .trim()
          .required('Please select the province you are currently living in.'),
      }),
      dateOfBirth: yup
        .date()
        .max(minBirthday, 'You must be at least 18 years old to apply.'),
      postalCode: yup.string().trim(),
      consentGiven: yup
        .boolean()
        .required('You must give consent to continue.')
        .test(
          'isTrue',
          'You must give your consent to continue.',
          (value) => value === true,
        ),
    }),
  });

  if (customerInfo?.coApplicant) {
    initialValues.coApplicant = {
      email: customerInfo.coApplicant.email,
    };

    validationSchema = validationSchema.shape({
      coApplicant: yup.object({
        email: yup
          .string()
          .email('Please provide a valid email.')
          .required('Please provide an email address for the co-applicant.'),
      }),
    });
  }

  if (loading || consentLoading) return <Loading />;
  if (error || consentError)
    return (
      <Hint state="error">
        There was a problem retrieving your application information. Please try
        again. If the issue persists, please try again later.
      </Hint>
    );

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <Heading element="h2" level="h3" fontFace="sans">
          Credit verification
        </Heading>
        <Text>
          We want to understand your credit history as part of the application
          process. Please provide the following information and authorization
          for us to proceed.
        </Text>
      </div>
      <AsyncHandler
        loading={submitLoading}
        error={submitError}
        errorMessage="The was a problem submitting your consent. Please try again. If the issue persists, please try again later."
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data) => {
            if (data?.primaryApplicant?.postalCode?.toString() === '') {
              data.primaryApplicant.postalCode = undefined;
            }
            await submitCreditReportConsent({ variables: { input: data } });

            router.push('/application/employment-details');
          }}
        >
          <Form className="flex flex-col gap-8">
            {(!submitted || error) && (
              <FormikConsumer>
                {(formik) => (
                  <FormikPrevent unsavedChanges={formik.dirty}></FormikPrevent>
                )}
              </FormikConsumer>
            )}
            <PrimaryApplicantForm {...customerInfo} />
            {customerInfo?.coApplicant && (
              <CoApplicantForm {...customerInfo.coApplicant} />
            )}

            <Button type="submit" className="sm:self-start w-32 sm:px-0">
              Submit
            </Button>
          </Form>
        </Formik>
      </AsyncHandler>
    </div>
  );
}

interface PrimaryApplicantFormProps {
  givenName: string;
  familyName: string;
}

function PrimaryApplicantForm({
  givenName,
  familyName,
}: PrimaryApplicantFormProps) {
  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4">
      <Heading element="h3" level="h4" fontFace="sans">
        Information for {givenName} {familyName}
      </Heading>
      <FieldSet title="Current address">
        <FormInput
          name="primaryApplicant.currentAddress.streetAddress"
          label="Street address"
        />

        <FormInput name="primaryApplicant.currentAddress.city" label="City" />

        <FormSelect
          name="primaryApplicant.currentAddress.province"
          label="Province"
          placeholder="Select one"
        >
          {Object.entries(provinceMap).map(([province, label]) => (
            <option key={province} value={province}>
              {label}
            </option>
          ))}
        </FormSelect>
      </FieldSet>

      <FieldSet title="Other information">
        <FormInput
          name="primaryApplicant.postalCode"
          label="Postal Code (optional)"
        />

        <FormInput
          type="date"
          name="primaryApplicant.dateOfBirth"
          label="Date of birth (optional)"
        />
      </FieldSet>

      <FieldSet title="Consent">
        <Text>
          I, {givenName} {familyName}, hereby grant Requity Homes Inc. and its
          affiliates permission to obtain my credit report from any credit
          reporting agency, including Equifax or TransUnion, for the purpose of
          facilitating a rent-to-own transaction.
        </Text>
        <FormControlGroup name="primaryApplicant.consentGiven">
          <FormCheckbox
            inline
            name="primaryApplicant.consentGiven"
            label="I agree"
          />
        </FormControlGroup>
      </FieldSet>
    </div>
  );
}

interface CoApplicantFormProps {
  givenName;
  familyName;
}

function CoApplicantForm({ givenName, familyName }: CoApplicantFormProps) {
  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4">
      <Heading element="h3" level="h4" fontFace="sans">
        Information for {givenName} {familyName}
      </Heading>
      <Text>
        We need to collect consent from {givenName} {familyName} directly. We
        will email them a link to a form where they can fill out their
        information. You may want to let them know that an email is coming from
        us. Please confirm that the email address we have for them is correct.
      </Text>
      <FormInput type="email" name="coApplicant.email" label="Email" />
    </div>
  );
}
