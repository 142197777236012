import { useState, useEffect, useCallback } from 'react';
import { convertBase64, DocumentTypes } from '../../utils';
import {
  CustomerFileCategory,
  useUploadCustomerFileMutation,
  useUpdateCustomerInfoMutation,
  CustomerFilesDocument,
  ApplicantInfoDocument,
  CurrentPage,
  useCustomerFileUploadUrlLazyQuery,
} from '../../graphql/generated';
import {
  Button,
  Hint,
  Icon,
  useVisibility,
  Popup,
  AsyncHandler,
} from '@requity-homes/component-library';
import NextLink from 'next/link';

import { FileUploadDropzone } from './file-upload-dropzone';
import { customerFileUploadMaxSizeBase64Bytes } from '@requity-homes/utils';

interface FileWithCategory {
  file: File;
  category: CustomerFileCategory;
}

interface FileUploadByCategoryProps {
  nextPage?: string;
  docsRequired: Partial<DocumentTypes>;
  currentPage?: CurrentPage;
  isForCoApplicant?: boolean;
  isFinalStep?: boolean;
  customUploadCallback?: () => void;
}

export function FileUploadByCategory({
  nextPage,
  docsRequired = {},
  isForCoApplicant,
  currentPage,
  isFinalStep,
  customUploadCallback,
}: FileUploadByCategoryProps) {
  const [uploadCustomerFile, { error: uploadFileError }] =
    useUploadCustomerFileMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: CustomerFilesDocument },
        { query: ApplicantInfoDocument },
      ],
    });
  const [updateCustomerInfo, { error: customerInfoUpdateError }] =
    useUpdateCustomerInfoMutation({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: ApplicantInfoDocument }],
    });

  const [getCustomerFileUploadUrl] = useCustomerFileUploadUrlLazyQuery({});

  const [fileUploadSuccessVisibility, handleFileUploadSuccessVisibility] =
    useVisibility();

  const [acceptedFiles, setAcceptedFiles] = useState<FileWithCategory[]>([]);
  const [loading, setLoading] = useState(false);
  const [invalidFilesErrMessages, setInvalidFilesErrMessages] = useState<
    string[]
  >([]);

  const errorMessages = [];

  useEffect(() => {
    setAcceptedFiles([]);
    setInvalidFilesErrMessages([]);
  }, [isForCoApplicant]);

  const getButtonState = useCallback(() => {
    let disabled = false;
    const categoriesAdded = acceptedFiles.map((item) => item.category);
    Object.keys(docsRequired).every((item) => {
      if (!categoriesAdded.includes(item as CustomerFileCategory)) {
        disabled = true;
        return false;
      }

      return true;
    });

    return disabled;
  }, [acceptedFiles, docsRequired]);

  const uploadFileBase64 = async ({ file, category }) => {
    const encodedFile = await convertBase64(file);
    await uploadCustomerFile({
      variables: {
        input: {
          content: encodedFile,
          fileName: file.name,
          documentType: category,
          isForCoApplicant,
        },
      },
    });
  };

  const uploadFileViaS3 = async ({ file, category }) => {
    setLoading(true);

    const { data } = await getCustomerFileUploadUrl({
      variables: {
        fileName: file.name,
      },
    });
    const uploadData = JSON.parse(data.customerFileUploadUrl);

    const formData = new FormData();

    formData.append('Content-Type', file.type);
    Object.entries(uploadData.fields).forEach(([k, v]) => {
      formData.append(k, v as string);
    });
    formData.append('file', file); // must be the last one

    const postRes = await fetch(uploadData.url, {
      method: 'POST',
      body: formData,
    });

    if (!postRes.ok) {
      throw postRes;
    }

    await uploadCustomerFile({
      variables: {
        input: {
          stagingFileKey: uploadData.fields.key,
          fileName: file.name,
          documentType: category,
          isForCoApplicant,
        },
      },
    });

    await updateCustomerInfo({
      variables: {
        input: {
          documentType: category,
          isFinalStep,
          isForCoApplicant,
          currentPage: currentPage,
        },
      },
    });

    setLoading(false);
  };

  const uploadFiles = async (files) => {
    const smallFiles = [],
      bigFiles = [];

    for (const file of files) {
      if (file.file.size > customerFileUploadMaxSizeBase64Bytes) {
        bigFiles.push(file);
      } else {
        smallFiles.push(file);
      }
    }
    await Promise.all(smallFiles.map((item) => uploadFileBase64(item)));

    for (const item of bigFiles) {
      await uploadFileViaS3(item);
    }
  };

  return (
    <div>
      {fileUploadSuccessVisibility && (
        <Popup title="Success">
          <div className="mt-2">
            <p className="text-gray-500">
              Your files were successfully uploaded
            </p>
          </div>
          <div className="flex justify-center gap-4">
            <div className="mt-10 flex justify-center">
              <Button
                as="a"
                hierarchy="secondary"
                className="inline-flex justify-center px-4 py-2 sm:px-0 px-0 text-sm w-32"
                onClick={() => handleFileUploadSuccessVisibility(false)}
              >
                Upload more
              </Button>
            </div>

            {nextPage && (
              <div className="mt-10 flex justify-center">
                <NextLink passHref href={nextPage}>
                  <Button
                    hierarchy="secondary"
                    className="inline-flex justify-center px-4 py-2 text-sm sm:px-0 px-0 w-32"
                  >
                    Continue
                  </Button>
                </NextLink>
              </div>
            )}
          </div>
        </Popup>
      )}

      <AsyncHandler
        loading={loading}
        error={uploadFileError || customerInfoUpdateError}
        errorMessage="We are unable to upload your files. Please refresh the page or try again later."
      >
        <div>
          {Object.keys(docsRequired).map((category) => (
            <FileUploadDropzone
              key={category}
              setAcceptedFiles={setAcceptedFiles}
              acceptedFiles={acceptedFiles}
              setInvalidFilesErrMessages={setInvalidFilesErrMessages}
              errorMessages={errorMessages}
              category={category as CustomerFileCategory}
            />
          ))}

          <div className="flex justify-center">
            <div className="flex flex-row justify-between m-0.5">
              <div>
                {invalidFilesErrMessages.map((message) => (
                  <Hint key={message} state="error" className="mr-2">
                    {message}
                  </Hint>
                ))}
              </div>
              {!!invalidFilesErrMessages.length && (
                <Icon
                  glyph="xCircle"
                  className="h-5 w-5 self-center ml-2"
                  onClick={() => setInvalidFilesErrMessages([])}
                />
              )}
            </div>
            {uploadFileError && (
              <Hint state="error">
                The was a problem upload your $
                {acceptedFiles.length > 1 ? 'files' : 'file'}. Please try again.
                If the issue persists, reach out to our support.
              </Hint>
            )}
          </div>
          <div className="flex justify-center mt-5">
            <Button
              className="self-center mb-10 w-32 sm:px-0"
              disabled={getButtonState()}
              onClick={async () => {
                await uploadFiles(acceptedFiles);

                setAcceptedFiles([]);

                if (
                  customUploadCallback &&
                  typeof customUploadCallback === 'function'
                ) {
                  customUploadCallback();
                } else {
                  handleFileUploadSuccessVisibility(true);
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </AsyncHandler>
    </div>
  );
}
