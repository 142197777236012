import { useRouter } from 'next/router';
import { Auth } from 'aws-amplify';
import { twMerge } from 'tailwind-merge';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  Icon,
  Link,
  Logo,
  resetTracking,
  trackEvent,
} from '@requity-homes/component-library';

import {
  ApplicationStatus,
  useApplicantsEmploymentDetailsQuery,
  useApplicationStatusQuery,
} from '../../graphql/generated';
import { client } from '../../graphql/apollo-client';
import {
  cookiesFavoriteListings,
  cookiesIdToken,
  deleteCrossDomainCookie,
} from '@requity-homes/utils';

export function Navigation({ openNavigation, setOpenNavigation }) {
  const router = useRouter();
  const isDocumentPage = router?.route === '/documents';

  const { data } = useApplicationStatusQuery({
    fetchPolicy: 'cache-and-network',
  });
  const applicationStatus = data?.customerInfo?.applicationStatus;

  const isPreApproved =
    applicationStatus !== ApplicationStatus.ApplicationStarted &&
    applicationStatus !== ApplicationStatus.PreApprovalDenied;

  const { data: employmentData } = useApplicantsEmploymentDetailsQuery({
    skip:
      applicationStatus === undefined ||
      applicationStatus === ApplicationStatus.ApplicationStarted,
  });

  const primaryApplicantEmploymentDataSubmitted =
    employmentData?.preApprovalApplication?.primaryApplicant?.employmentDetails;

  async function signOut() {
    try {
      deleteCrossDomainCookie(cookiesIdToken);
      deleteCrossDomainCookie(cookiesFavoriteListings);

      await Auth.signOut();

      trackEvent('Sign out');
      resetTracking();

      client.resetStore();

      if (router.asPath !== '/') {
        router.push('/');
      }
    } catch (error) {
      console.error('There was an error signing out', error);
    }
  }

  const fullApprovalColor =
    applicationStatus === ApplicationStatus.FullApprovalSubmitted
      ? 'teal-dark'
      : 'teal-light';

  let preApprovalColor = '';
  if (
    applicationStatus === ApplicationStatus.PreApprovalAccepted ||
    applicationStatus === ApplicationStatus.FullApprovalStarted ||
    applicationStatus === ApplicationStatus.EmploymentDetailsSubmitted ||
    applicationStatus === ApplicationStatus.BankStatementSubmitted ||
    applicationStatus ===
      ApplicationStatus.IncomeVerificationDocumentsSubmitted ||
    applicationStatus === ApplicationStatus.FullApprovalSubmitted
  ) {
    preApprovalColor = 'teal-dark';
  } else {
    preApprovalColor =
      applicationStatus === ApplicationStatus.PreApprovalDenied
        ? 'error-light'
        : 'teal-light';
  }

  return (
    <aside
      className={`md:w-2/12 lg:w-3/12 bg-coral-light py-4 px-6 font-sans text-md text-grey-2 ${
        openNavigation ? 'flex-1' : ''
      }`}
    >
      <div className="flex flex-col h-full">
        <div
          className={`md:mb-20 flex justify-between md:pb-0 ${
            openNavigation ? 'pb-24' : ''
          }`}
        >
          <Link href="/">
            <Logo color="coral" className="h-6" />
          </Link>

          <span
            className="md:hidden text-coral-dark -mt-4 cursor-pointer text-4xl font-black"
            onClick={() => setOpenNavigation((prev) => !prev)}
          >
            ...
          </span>
        </div>

        <div
          className={twMerge(
            `flex flex-col justify-between flex-1 md:flex ${
              openNavigation ? 'flex' : 'hidden'
            }`,
          )}
        >
          <div className="flex-1">
            <Link
              id="applicationprogress"
              href="/"
              className={`flex mb-8 relative ${
                openNavigation ? '' : 'justify-center'
              } lg:justify-start no-underline text-grey-2 hover:text-coral-dark ${
                isDocumentPage ? '' : 'text-coral-dark'
              }`}
              onClick={() => setOpenNavigation(false)}
            >
              <Icon glyph="file-2" className="w-6 h-6 block -ml-2" />
              <span
                className={`sm:hidden md:block lg:hidden absolute -bottom-5 text-center -mx-8`}
                style={{ fontSize: 10 }}
              >
                <ReactTooltip
                  anchorId="applicationprogress"
                  place="bottom"
                  content="Application Progress"
                />
              </span>
              <h2 className={`${openNavigation ? '' : 'hidden'} ml-4 lg:block`}>
                Application Progress
              </h2>
            </Link>
            <div className="ml-10 mb-10">
              <div
                className={`${
                  openNavigation ? '' : 'hidden'
                } ml-1 lg:block flex-1`}
              >
                <div className="mt-8 mb-4">
                  <div className="pb-8 mb-1 border-l-2 border-teal-dark pl-3 ml-2 relative">
                    <span
                      className="rounded-full w-2 h-2 block absolute top-0 left-0 -mt-1 -ml-1 bg-teal-dark"
                      style={{ marginLeft: -5 }}
                    />
                    <h2 className="absolute -top-1 -mt-1.5">
                      Account Creation
                    </h2>
                  </div>

                  <div
                    className={`pb-8 mb-1 border-l-2 pl-3 ml-2 relative border-${preApprovalColor}`}
                  >
                    <span
                      className={`rounded-full w-2 h-2 block absolute top-0 left-0 -mt-1 -ml-1 bg-${preApprovalColor}`}
                      style={{ marginLeft: -5 }}
                    />
                    <h2 className="absolute -top-1 -mt-1.5">Pre-Approval</h2>
                  </div>
                  <div
                    className={`pb-8 mb-1 border-l-2 pl-3 ml-2 relative border-${fullApprovalColor}`}
                  >
                    <span
                      className={`rounded-full w-2 h-2 block absolute top-0 left-0 -mt-1 -ml-1 bg-${fullApprovalColor}`}
                      style={{ marginLeft: -5 }}
                    />
                    <h2 className="absolute -top-1 -mt-1.5">Full Approval</h2>
                  </div>
                  <div className="mb-1 border-l-2 pl-3 ml-2 relative">
                    <span className="rounded-full w-2 h-2 block absolute top-0 left-0 -mt-1 -ml-1 bg-teal-light" />
                    <h2 className="absolute -top-1 -mt-1.5">
                      Full Approval Review
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            {primaryApplicantEmploymentDataSubmitted && (
              <Link
                id="documents"
                href="/documents"
                className={`flex mb-8 relative ${
                  openNavigation ? '' : 'justify-center'
                } lg:justify-start no-underline text-grey-2 hover:text-coral-dark ${
                  isDocumentPage ? 'text-coral-dark' : ''
                }`}
                onClick={() => setOpenNavigation(false)}
              >
                <Icon glyph="downloadDocuments" className="w-5 h-5" color="" />
                <span
                  className={`sm:hidden md:block lg:hidden absolute -bottom-5`}
                  style={{ fontSize: 10 }}
                >
                  <ReactTooltip
                    anchorId="documents"
                    place="bottom"
                    content="Documents"
                  />
                </span>
                <h2
                  className={`${openNavigation ? '' : 'hidden'} ml-4 lg:block`}
                >
                  Documents
                </h2>
              </Link>
            )}
            {isPreApproved && (
              <Link
                id="resources"
                href="https://www.requityhomes.com/client-resource/main"
                target="_blank"
                className={`flex mb-8 relative ${
                  openNavigation ? '' : 'justify-center'
                } lg:justify-start no-underline text-grey-2 hover:text-coral-dark`}
                onClick={() => setOpenNavigation(false)}
              >
                <Icon glyph="star" className="w-5 h-5" />
                <span
                  className={`sm:hidden md:block lg:hidden absolute -bottom-5`}
                  style={{ fontSize: 10 }}
                >
                  <ReactTooltip
                    anchorId="resources"
                    place="bottom"
                    content="Resources"
                  />
                </span>
                <h2
                  className={`${openNavigation ? '' : 'hidden'} ml-4 lg:block`}
                >
                  Resources
                </h2>
              </Link>
            )}
            <Link
              id="faq"
              href="https://www.requityhomes.com/faq"
              target="_blank"
              className={`flex mb-8 relative ${
                openNavigation ? '' : 'justify-center'
              } lg:justify-start no-underline text-grey-2 hover:text-coral-dark`}
              onClick={() => setOpenNavigation(false)}
            >
              <Icon glyph="bulb" className="w-5 h-5" />
              <span
                className={`sm:hidden md:block lg:hidden absolute -bottom-5`}
                style={{ fontSize: 10 }}
              >
                <ReactTooltip anchorId="faq" place="bottom" content="FAQ" />
              </span>
              <h2 className={`${openNavigation ? '' : 'hidden'} ml-4 lg:block`}>
                FAQ
              </h2>
            </Link>
            <Link
              id="home"
              href="https://www.requityhomes.com"
              target="_blank"
              className={`flex mb-8 relative ${
                openNavigation ? '' : 'justify-center'
              } lg:justify-start no-underline text-grey-2 hover:text-coral-dark`}
              onClick={() => setOpenNavigation(false)}
            >
              <Icon glyph="home" className="w-5 h-5" />
              <span
                className={`sm:hidden md:block lg:hidden absolute -bottom-5`}
                style={{ fontSize: 10 }}
              >
                <ReactTooltip anchorId="home" place="bottom" content="Home" />
              </span>
              <h2 className={`${openNavigation ? '' : 'hidden'} ml-4 lg:block`}>
                Home
              </h2>
            </Link>
          </div>

          <Link
            id="signout"
            href=""
            onClick={signOut}
            className={`flex mb-8 mt-4 relative ${
              openNavigation ? '' : 'justify-center'
            } lg:justify-start no-underline text-grey-2 hover:text-coral-dark`}
          >
            <Icon glyph="exit" className="w-5 h-5" />
            <span
              className={`sm:hidden md:block lg:hidden absolute -bottom-5`}
              style={{ fontSize: 10 }}
            >
              <ReactTooltip
                anchorId="signout"
                place="bottom"
                content="Sign Out"
              />
            </span>
            <h2 className={`${openNavigation ? '' : 'hidden'} ml-2 lg:block`}>
              Sign Out
            </h2>
          </Link>
        </div>
      </div>
    </aside>
  );
}
