import { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Form, Formik, FormikConsumer } from 'formik';
import * as yup from 'yup';
import { cloneDeep } from 'lodash';

import {
  Button,
  FieldSet,
  FormCheckbox,
  FormControlGroup,
  FormRadioBoolean,
  FormInput,
  NumericFormatInput,
  FormSelect,
  Heading,
  Text,
  AsyncHandler,
} from '@requity-homes/component-library';

import {
  useSubmitEmploymentDetailsMutation,
  ApplicantsEmploymentDetailsDocument,
  useApplicationDetailsQuery,
  useApplicantInfoQuery,
  EmploymentInput,
  FrequencyOfPayment,
} from '../../graphql/generated';
import {
  salaryFrequencyMap,
  yupTransformNumeric,
  convertStringToFloat,
} from '../../utils';
import { FormikPrevent } from '../formik-prevent/formik-prevent';
import { InfoBox } from '../info-box/info-box';

export function EmploymentDetailsForm() {
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const router = useRouter();

  const { data: customerData, loading: loadingCustomerData } =
    useApplicantInfoQuery();
  const customerInfo = customerData?.customerInfo;

  const { data: applicationData, loading: loadingApplicationData } =
    useApplicationDetailsQuery();
  const preApprovalApplication = applicationData?.preApprovalApplication;

  const [submitForCoApplicant, setSubmitForCoApplicant] = useState(
    !!router?.query.forCoApplicant,
  );

  const [
    submitEmploymentDetails,
    { loading: submitLoading, error: submitError, called: submitted },
  ] = useSubmitEmploymentDetailsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ApplicantsEmploymentDetailsDocument }],
  });

  const getInitialValuesFromEmploymentDetails = (employmentDetails) => {
    const {
      traditionalEmployment,
      selfEmployed,
      pensionAndDisability,
      childTaxBenefits,
      homeMaker,
      otherIncomeSource,
    } = employmentDetails;

    return {
      employmentStatus: {
        traditionalEmployment: !!traditionalEmployment,
        selfEmployed: !!selfEmployed,
        pensionAndDisability: !!pensionAndDisability,
        childTaxBenefits: !!childTaxBenefits,
        homeMaker: !!homeMaker,
        otherIncomeSource: !!otherIncomeSource,
      },
      traditionalEmployment: {
        employerName: traditionalEmployment?.employerName,
        frequencyOfPayment: traditionalEmployment?.frequencyOfPayment,
        grossIncomePerPaycheck: traditionalEmployment?.grossIncomePerPaycheck,
        startDate: traditionalEmployment?.startDate,
      },
      selfEmployed: {
        businessName: selfEmployed?.businessName,
        isBusinessIncorporated: selfEmployed?.isBusinessIncorporated,
        averageMonthlyIncome: selfEmployed?.averageMonthlyIncome,
        startDate: selfEmployed?.startDate,
      },
      pensionAndDisability: {
        monthlyAmount: pensionAndDisability?.monthlyAmount,
      },
      childTaxBenefits: {
        monthlyAmount: childTaxBenefits?.monthlyAmount,
      },
      otherIncomeSource: {
        source: otherIncomeSource?.source,
        monthlyAmount: otherIncomeSource?.monthlyAmount,
      },
      homeMaker: !!homeMaker,
    };
  };

  interface EmploymentInputValues {
    employmentStatus: {
      traditionalEmployment: boolean;
      selfEmployed: boolean;
      pensionAndDisability: boolean;
      childTaxBenefits: boolean;
      homeMaker: boolean;
      otherIncomeSource: boolean;
    };
    traditionalEmployment: {
      employerName: string;
      frequencyOfPayment: FrequencyOfPayment;
      grossIncomePerPaycheck: number;
      startDate: string;
    };
    selfEmployed: {
      businessName: string;
      isBusinessIncorporated: boolean;
      averageMonthlyIncome: number;
      startDate: string;
    };
    pensionAndDisability: {
      monthlyAmount: number;
    };
    childTaxBenefits: {
      monthlyAmount: number;
    };
    otherIncomeSource: {
      source: string;
      monthlyAmount: number;
    };
    homeMaker: boolean;
  }

  const emptyFields = useMemo(
    () => ({
      employmentStatus: {
        traditionalEmployment: false,
        selfEmployed: false,
        pensionAndDisability: false,
        childTaxBenefits: false,
        homeMaker: false,
        otherIncomeSource: false,
      },
      traditionalEmployment: {
        employerName: '',
        frequencyOfPayment: undefined,
        grossIncomePerPaycheck: undefined,
        startDate: '',
      },
      selfEmployed: {
        businessName: '',
        isBusinessIncorporated: false,
        averageMonthlyIncome: undefined,
        startDate: '',
      },
      pensionAndDisability: {
        monthlyAmount: undefined,
      },
      childTaxBenefits: {
        monthlyAmount: undefined,
      },
      otherIncomeSource: {
        source: '',
        monthlyAmount: undefined,
      },
      homeMaker: false,
    }),
    [],
  );

  const initialValues = useMemo(() => {
    if (preApprovalApplication) {
      const { primaryApplicant, coApplicant } = preApprovalApplication || {};

      if (coApplicant?.employmentDetails && submitForCoApplicant) {
        return getInitialValuesFromEmploymentDetails(
          coApplicant.employmentDetails,
        );
      }

      if (primaryApplicant?.employmentDetails && !submitForCoApplicant) {
        return getInitialValuesFromEmploymentDetails(
          primaryApplicant.employmentDetails,
        );
      }
    }

    return emptyFields;
  }, [preApprovalApplication, submitForCoApplicant, emptyFields]);

  const validationSchema = yup.object({
    employmentStatus: yup.object({
      traditionalEmployment: yup.boolean(),
      selfEmployed: yup.boolean(),
      pensionAndDisability: yup.boolean(),
      childTaxBenefits: yup.boolean(),
      homeMaker: yup.boolean(),
      otherIncomeSource: yup.boolean(),
    }),
    traditionalEmployment: yup
      .object({})
      .when('employmentStatus.traditionalEmployment', {
        is: true,
        then: (schema) =>
          schema.shape({
            employerName: yup.string().required('Please provide a name.'),
            frequencyOfPayment: yup.string().required('Please select a value.'),
            grossIncomePerPaycheck: yup
              .number()
              .transform(yupTransformNumeric)
              .required('Please enter an amount.')
              .min(0, 'Please provide a positive number.'),
            startDate: yup.date().required('Please select a date.'),
          }),
      }),
    selfEmployed: yup.object({}).when('employmentStatus.selfEmployed', {
      is: true,
      then: (schema) =>
        schema.shape({
          businessName: yup.string().required('Please provide a name.'),
          isBusinessIncorporated: yup
            .boolean()
            .required('Please select a value.'),
          averageMonthlyIncome: yup.string().required('Please select a value.'),
          startDate: yup.date().required('Please select a date.'),
        }),
    }),
    pensionAndDisability: yup
      .object({})
      .when('employmentStatus.pensionAndDisability', {
        is: true,
        then: (schema) =>
          schema.shape({
            monthlyAmount: yup
              .number()
              .transform(yupTransformNumeric)
              .required('Please enter an amount.')
              .min(0, 'Please provide a positive number.'),
          }),
      }),
    childTaxBenefits: yup.object({}).when('employmentStatus.childTaxBenefits', {
      is: true,
      then: (schema) =>
        schema.shape({
          monthlyAmount: yup
            .number()
            .transform(yupTransformNumeric)
            .required('Please enter an amount.')
            .min(0, 'Please provide a positive number.'),
        }),
    }),
    otherIncomeSource: yup
      .object({})
      .when('employmentStatus.otherIncomeSource', {
        is: true,
        then: (schema) =>
          schema.shape({
            source: yup.string().required('Please provide a name.'),
            monthlyAmount: yup
              .number()
              .transform(yupTransformNumeric)
              .required('Please enter an amount.')
              .min(0, 'Please provide a positive number.'),
          }),
      }),
  });

  const prepareFormData = (inputData: EmploymentInputValues) => {
    const formData = cloneDeep(inputData);

    if (formData.employmentStatus.traditionalEmployment) {
      formData.traditionalEmployment.grossIncomePerPaycheck =
        convertStringToFloat(
          formData.traditionalEmployment.grossIncomePerPaycheck,
        );
    } else {
      delete formData.traditionalEmployment;
    }

    if (formData.employmentStatus.selfEmployed) {
      formData.selfEmployed.averageMonthlyIncome = convertStringToFloat(
        formData.selfEmployed.averageMonthlyIncome,
      );
    } else {
      delete formData.selfEmployed;
    }

    if (formData.employmentStatus.pensionAndDisability) {
      formData.pensionAndDisability.monthlyAmount = convertStringToFloat(
        formData.pensionAndDisability.monthlyAmount,
      );
    } else {
      delete formData.pensionAndDisability;
    }

    if (formData.employmentStatus.childTaxBenefits) {
      formData.childTaxBenefits.monthlyAmount = convertStringToFloat(
        formData.childTaxBenefits.monthlyAmount.toString(10).replace(/,/g, ''),
      );
    } else {
      delete formData.childTaxBenefits;
    }

    if (formData.employmentStatus.otherIncomeSource) {
      formData.otherIncomeSource.monthlyAmount = convertStringToFloat(
        formData.otherIncomeSource.monthlyAmount.toString(10).replace(/,/g, ''),
      );
    } else {
      delete formData.otherIncomeSource;
    }

    if (formData.employmentStatus.homeMaker) {
      const {
        traditionalEmployment,
        selfEmployed,
        pensionAndDisability,
        childTaxBenefits,
        homeMaker,
        otherIncomeSource,
      } = formData.employmentStatus;

      const selectedHomeMakerOnly =
        homeMaker &&
        !traditionalEmployment &&
        !selfEmployed &&
        !pensionAndDisability &&
        !childTaxBenefits &&
        !otherIncomeSource;

      if (selectedHomeMakerOnly) {
        return {
          forCoApplicant: submitForCoApplicant,
          employmentDetails: {
            homeMaker: true,
          },
        };
      }
    }

    return {
      forCoApplicant: submitForCoApplicant,
      employmentDetails: {
        traditionalEmployment: formData.traditionalEmployment,
        selfEmployed: formData.selfEmployed,
        pensionAndDisability: formData.pensionAndDisability,
        childTaxBenefits: formData.childTaxBenefits,
        homeMaker: formData.employmentStatus.homeMaker || undefined,
        otherIncomeSource: formData.otherIncomeSource,
      },
    };
  };

  return (
    <div className="flex flex-col flex-1 md:h-5/6">
      <AsyncHandler
        loading={loadingApplicationData || submitLoading || loadingCustomerData}
        error={submitError}
        errorMessage="There was a problem submitting your employment data. Please refresh the page or try again later."
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (submissionData, { resetForm }) => {
            const response = await submitEmploymentDetails({
              variables: {
                input: prepareFormData(submissionData) as EmploymentInput,
              },
            });

            if (preApprovalApplication?.hasCoApplicant) {
              if (
                response.data.submitEmploymentDetails.primaryApplicant
                  .employmentDetails &&
                response.data.submitEmploymentDetails.coApplicant
                  .employmentDetails
              ) {
                router.push('/application/statements-upload');
              } else if (
                response.data.submitEmploymentDetails.primaryApplicant
                  .employmentDetails
              ) {
                setSubmitForCoApplicant(true);
                resetForm();
                setShowDetailsForm(false);
              } else {
                setSubmitForCoApplicant(false);
                resetForm();
                setShowDetailsForm(false);
              }
            } else {
              router.push('/application/statements-upload');
            }
          }}
        >
          {({ values, isValid, setValues }) => {
            const {
              traditionalEmployment,
              selfEmployed,
              pensionAndDisability,
              childTaxBenefits,
              homeMaker,
              otherIncomeSource,
            } = values.employmentStatus;

            const selectedHomeMakerOnly =
              homeMaker &&
              !traditionalEmployment &&
              !selfEmployed &&
              !pensionAndDisability &&
              !childTaxBenefits &&
              !otherIncomeSource;

            const employmentStatusSelected =
              traditionalEmployment ||
              selfEmployed ||
              pensionAndDisability ||
              childTaxBenefits ||
              homeMaker ||
              otherIncomeSource;

            const { primaryApplicant, coApplicant } =
              preApprovalApplication || {};

            return (
              <Form className="flex flex-col gap-6 md:flex-row flex-1 md:h-5/6">
                <div className="flex flex-col order-1 md:order-2 md:w-4/12 pb-12">
                  <div className="flex-1">
                    <InfoBox
                      title="Full Approval"
                      progressPercent={showDetailsForm ? 40 : 20}
                    >
                      During the full approval stage, you will provide
                      documentation that supports the information you provided
                      during the pre-approval stage. This may include items like
                      credit reports, bank statements, or tax returns.
                    </InfoBox>
                  </div>

                  <div className="hidden md:flex justify-center">
                    {showDetailsForm ? (
                      <div className="flex justify-center items-center gap-2 flex-wrap">
                        <Button
                          type="button"
                          className="mx-0 w-32 sm:px-0"
                          hierarchy="secondary"
                          onClick={() => setShowDetailsForm(false)}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          className="mx-0 w-32 sm:px-0"
                          disabled={!isValid}
                        >
                          Submit
                        </Button>
                      </div>
                    ) : (
                      <>
                        {selectedHomeMakerOnly ? (
                          <Button
                            type="submit"
                            className="sm:self-start w-32 sm:px-0"
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="sm:self-start w-32 sm:px-0"
                            disabled={!employmentStatusSelected}
                            onClick={() => setShowDetailsForm(true)}
                          >
                            Next
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="flex flex-col pt-0 order-2 md:order-1 md:w-8/12 md:max-h-full overflow-y-auto md:mb-8">
                  {preApprovalApplication?.hasCoApplicant && (
                    <div className="flex h-10 w-full">
                      <div
                        className={`h-10 flex cursor-pointer flex-1 rounded-t-xl justify-center items-center ${
                          submitForCoApplicant ? 'bg-grey-5' : 'bg-grey-6'
                        }`}
                        onClick={() => {
                          setSubmitForCoApplicant(false);
                          setValues(
                            primaryApplicant.employmentDetails
                              ? getInitialValuesFromEmploymentDetails(
                                  primaryApplicant.employmentDetails,
                                )
                              : emptyFields,
                          );
                          setShowDetailsForm(false);
                        }}
                      >
                        <Text>{`${customerInfo?.givenName} ${customerInfo?.familyName}`}</Text>
                      </div>
                      <div className="w-2 h-10 bg-white"></div>

                      <div
                        className={`h-10 flex cursor-pointer flex-1 rounded-t-xl justify-center items-center ${
                          !primaryApplicant.employmentDetails
                            ? 'cursor-not-allowed'
                            : ''
                        } ${submitForCoApplicant ? 'bg-grey-6' : 'bg-grey-5'}`}
                        onClick={() => {
                          if (primaryApplicant.employmentDetails) {
                            setSubmitForCoApplicant(true);
                            setValues(
                              coApplicant.employmentDetails
                                ? getInitialValuesFromEmploymentDetails(
                                    coApplicant.employmentDetails,
                                  )
                                : emptyFields,
                            );
                            setShowDetailsForm(false);
                          }
                        }}
                      >
                        <Text>
                          {`${customerInfo?.coApplicant?.givenName || ''} ${
                            customerInfo?.coApplicant?.familyName || ''
                          }`}
                        </Text>
                      </div>
                    </div>
                  )}

                  <div
                    className={`px-8 bg-grey-6 flex flex-col flex-1 gap-6 pt-12 ${
                      preApprovalApplication?.hasCoApplicant
                        ? ''
                        : 'rounded-t-xl'
                    }`}
                  >
                    <div className="flex flex-col gap-4">
                      <Heading element="h2" level="h3" fontFace="sans">
                        {submitForCoApplicant
                          ? 'Co-applicant Employment Verification'
                          : 'Primary Applicant Employment Verification'}
                      </Heading>
                    </div>

                    {showDetailsForm ? (
                      <>
                        {values.employmentStatus.traditionalEmployment && (
                          <EmployerDetails
                            submitForCoApplicant={submitForCoApplicant}
                          />
                        )}
                        {values.employmentStatus.selfEmployed && (
                          <SelfEmploymentDetails
                            submitForCoApplicant={submitForCoApplicant}
                          />
                        )}
                        {values.employmentStatus.pensionAndDisability && (
                          <PensionAndDisability
                            submitForCoApplicant={submitForCoApplicant}
                          />
                        )}
                        {values.employmentStatus.childTaxBenefits && (
                          <ChildTaxBenefits
                            submitForCoApplicant={submitForCoApplicant}
                          />
                        )}
                        {values.employmentStatus.otherIncomeSource && (
                          <OtherIncomeSource
                            submitForCoApplicant={submitForCoApplicant}
                          />
                        )}
                        <div className="flex justify-center md:hidden gap-2">
                          <Button
                            type="button"
                            className="sm:self-start mx-0 w-32 sm:px-0"
                            hierarchy="secondary"
                            onClick={() => setShowDetailsForm(false)}
                          >
                            Back
                          </Button>
                          <Button
                            type="submit"
                            disabled={!isValid}
                            className="sm:self-start mx-0 w-32 sm:px-0"
                          >
                            Submit
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {(!submitted || submitError) && (
                          <FormikConsumer>
                            {(formik) => (
                              <FormikPrevent
                                unsavedChanges={formik.dirty}
                              ></FormikPrevent>
                            )}
                          </FormikConsumer>
                        )}
                        <SelectEmploymentStatus
                          setShowDetailsForm={setShowDetailsForm}
                          submitForCoApplicant={submitForCoApplicant}
                          employmentStatusSelected={employmentStatusSelected}
                          selectedHomeMakerOnly={selectedHomeMakerOnly}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </AsyncHandler>
    </div>
  );
}

function SelectEmploymentStatus({
  setShowDetailsForm,
  submitForCoApplicant,
  selectedHomeMakerOnly,
  employmentStatusSelected,
}) {
  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4 mb-5">
      <Text>
        What are your {submitForCoApplicant ? "co-applicant's" : ''} sources of
        income? Please select all that apply.
      </Text>
      <FormControlGroup name="employmentStatus.traditionalEmployment">
        <FormCheckbox
          label="Traditional employment (hourly, salaried, or commission)"
          name="employmentStatus.traditionalEmployment"
        />
      </FormControlGroup>

      <FormControlGroup name="employmentStatus.selfEmployed">
        <FormCheckbox
          label="Self-employed"
          name="employmentStatus.selfEmployed"
        />
      </FormControlGroup>
      <FormControlGroup name="employmentStatus.pensionAndDisability">
        <FormCheckbox
          label="Pension & disability incomes"
          name="employmentStatus.pensionAndDisability"
        />
      </FormControlGroup>
      <FormControlGroup name="employmentStatus.childTaxBenefits">
        <FormCheckbox
          label="Child tax benefits"
          name="employmentStatus.childTaxBenefits"
        />
      </FormControlGroup>

      <FormControlGroup name="employmentStatus.homeMaker">
        <FormCheckbox label="Homemaker" name="employmentStatus.homeMaker" />
      </FormControlGroup>

      <FormControlGroup name="employmentStatus.otherIncomeSource">
        <FormCheckbox label="Other" name="employmentStatus.otherIncomeSource" />
      </FormControlGroup>
      <div className="flex justify-center md:hidden">
        {selectedHomeMakerOnly ? (
          <Button type="submit" className="sm:self-start w-32 sm:px-0">
            Submit
          </Button>
        ) : (
          <Button
            type="button"
            className="sm:self-start w-32 sm:px-0"
            disabled={!employmentStatusSelected}
            onClick={() => setShowDetailsForm(true)}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
}

function EmployerDetails({ submitForCoApplicant }) {
  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4 mb-5">
      <FieldSet title="Traditional Employment">
        <Text>
          {submitForCoApplicant ? 'Co-applicant ' : ''} Employer&apos;s Details
        </Text>
        <FormInput
          name="traditionalEmployment.employerName"
          label="Employer's name"
        />

        <FormInput
          type="date"
          name="traditionalEmployment.startDate"
          label="Start date"
        />

        <FormSelect
          name="traditionalEmployment.frequencyOfPayment"
          label="How often do you get paid?"
          placeholder="Select one"
        >
          {Object.entries(salaryFrequencyMap).map(([timeline, label]) => (
            <option key={timeline} value={timeline}>
              {label}
            </option>
          ))}
        </FormSelect>

        <NumericFormatInput
          name="traditionalEmployment.grossIncomePerPaycheck"
          label="Average pre-tax income per paycheck"
        />
      </FieldSet>
    </div>
  );
}

function SelfEmploymentDetails({ submitForCoApplicant }) {
  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4 mb-5">
      <FieldSet title="Self Employment">
        <Text>
          {submitForCoApplicant ? 'Co-applicant ' : ''} Self-employment Details
        </Text>
        <FormInput name="selfEmployed.businessName" label="Business name" />

        <FormControlGroup
          name="selfEmployed.isBusinessIncorporated"
          label="Is your business incorporated?"
        >
          <FormRadioBoolean name="selfEmployed.isBusinessIncorporated" />
        </FormControlGroup>

        <NumericFormatInput
          name="selfEmployed.averageMonthlyIncome"
          label="Average monthly income"
        />

        <FormInput
          type="date"
          name="selfEmployed.startDate"
          label="Start date"
        />
      </FieldSet>
    </div>
  );
}

function PensionAndDisability({ submitForCoApplicant }) {
  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4 mb-5">
      <FieldSet title="Pension & Disability Income">
        <Text>
          {submitForCoApplicant ? 'Co-applicant ' : ''} Pension & Disability
          Income Details
        </Text>

        <NumericFormatInput
          name="pensionAndDisability.monthlyAmount"
          label="Average monthly income"
        />
      </FieldSet>
    </div>
  );
}

function ChildTaxBenefits({ submitForCoApplicant }) {
  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4 mb-5">
      <FieldSet title="Child Tax Benefit">
        <Text>
          {submitForCoApplicant ? 'Co-applicant ' : ''} Child Tax Benefits
          Details
        </Text>

        <NumericFormatInput
          name="childTaxBenefits.monthlyAmount"
          label="Average monthly income"
        />
      </FieldSet>
    </div>
  );
}

function OtherIncomeSource({ submitForCoApplicant }) {
  return (
    <div className="flex flex-col gap-6 border border-grey-5 p-4 mb-5">
      <FieldSet title="Other Income">
        <Text>
          {submitForCoApplicant ? 'Co-applicant ' : ''} Other Income Details
        </Text>

        <FormInput
          name="otherIncomeSource.source"
          label="What is your source of income?"
        />

        <NumericFormatInput
          name="otherIncomeSource.monthlyAmount"
          label="Average monthly income"
        />
      </FieldSet>
    </div>
  );
}
