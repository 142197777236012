export interface DocumentTypes {
  NOTICE_OF_ASSESSMENT: string;
  EMPLOYMENT_LETTER: string;
  RECENT_PAY_STUBS: string;
  ARTICLES_OF_INCORPORATION: string;
  PERSONAL_TAX_RETURNS: string;
  CORPORATE_FINANCIAL_STATEMENT_AND_TAX_RETURNS: string;
  OTHER_INCOME_SOURCE: string;
}

export const generateRequiredDocs: (
  employmentStatus,
) => Partial<DocumentTypes> = (employmentStatus) => {
  const docsMap: Partial<DocumentTypes> = {
    NOTICE_OF_ASSESSMENT: 'Notice of Assessment',
  };

  if (employmentStatus?.traditionalEmployment) {
    docsMap.EMPLOYMENT_LETTER = 'Employment Letter';
    docsMap.RECENT_PAY_STUBS = 'Recent Pay Stubs';
  }
  if (
    employmentStatus?.selfEmployed &&
    !employmentStatus.selfEmployed.isBusinessIncorporated
  )
    docsMap.PERSONAL_TAX_RETURNS = 'Personal Tax Returns';
  if (
    employmentStatus?.selfEmployed &&
    employmentStatus.selfEmployed.isBusinessIncorporated
  )
    docsMap.ARTICLES_OF_INCORPORATION = 'Articles of Incorporation';
  if (
    employmentStatus?.selfEmployed &&
    employmentStatus.selfEmployed.isBusinessIncorporated
  )
    docsMap.CORPORATE_FINANCIAL_STATEMENT_AND_TAX_RETURNS =
      'Corporate Financial Statement & Tax Returns';

  return docsMap;
};
