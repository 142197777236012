import { CustomerFileCategory } from '../graphql/generated';

export type FileCategoryMap = {
  [key in CustomerFileCategory]?: string;
};

export const bankStatementFileCategoryMap: FileCategoryMap = {
  FINANCIAL: 'Bank Statement',
};

export const identityVerificationFileCategoryMap: FileCategoryMap = {
  [CustomerFileCategory.Identity]: 'Identity',
};
