import { useState } from 'react';
import { Heading, Button, FormInput } from '@requity-homes/component-library';
import { BackArrow, MultipleDropDownSelect } from '../';
import { shortenString } from '../../utils';
import { Text } from '@requity-homes/component-library';
import { useRouter } from 'next/router';

export function AddCoApplicantForm({
  coApplicantIncomeSources,
  disabled,
  handleSubmit,
}) {
  const [openCoApplicantDropdown, setOpenCoApplicantDropdown] = useState(false);
  const router = useRouter();

  return (
    <div className="flex flex-col justify-between h-full flex-1">
      <div className="flex flex-col gap-4">
        <div className="flex gap-10 items-start">
          <BackArrow
            onClick={() => router.back()}
            className="hidden md:absolute md:left-2 md:top-2 md:block"
          />
          <Heading
            level="h3"
            element="h3"
            fontFace="sans"
            className="w-full text-center mb-4"
          >
            Boost your home budget!
          </Heading>
        </div>
        <Text className="text-center my-4 md:text-base text-sm">
          Having a co-buyer can increase your budget. A co-buyer is any person
          over 18 years old who will be living in your home with you.
        </Text>
        <>
          <FormInput
            className="shadow-inset rounded-xl"
            name="givenName"
            label=""
            placeholder="Co-applicant's first name"
          />
          <FormInput
            className="shadow-inset rounded-xl"
            name="familyName"
            label=""
            placeholder="Co-applicant's last name"
          />
          <FormInput
            className="shadow-inset rounded-xl"
            name="email"
            label=""
            placeholder="Co-applicant's email name"
          />
          <MultipleDropDownSelect
            openDropdown={openCoApplicantDropdown}
            setOpenDropdown={setOpenCoApplicantDropdown}
            title={
              coApplicantIncomeSources.length > 0
                ? shortenString(coApplicantIncomeSources.join(', '), 32)
                : 'Select income source'
            }
            items={[
              {
                label:
                  'Traditional employment (hourly, salaried, or commission)',
                name: 'preQualificationIncomeSource.traditionalEmployment',
              },
              {
                label: 'Self-employment',
                name: 'preQualificationIncomeSource.selfEmployed',
              },
              {
                label: 'Pension & disability incomes',
                name: 'preQualificationIncomeSource.pensionAndDisability',
              },
              {
                label: 'Child tax benefits',
                name: 'preQualificationIncomeSource.childTaxBenefits',
              },
              {
                label: 'Homemaker',
                name: 'preQualificationIncomeSource.homeMaker',
              },
              {
                label: 'Other',
                name: 'preQualificationIncomeSource.otherIncomeSource',
              },
            ]}
          />
        </>
      </div>
      <Button
        disabled={disabled}
        type="button"
        color="coral"
        className={`sm:self-center w-full border-none shadow-inset md:mb-6 ${
          disabled
            ? '!bg-grey-5 text-grey-3'
            : '!bg-coral-medium shadow-md-dark'
        }`}
        onClick={() => handleSubmit()}
      >
        Next
      </Button>
    </div>
  );
}
