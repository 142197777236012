import { useState } from 'react';
import { Text, Icon, AsyncHandler } from '@requity-homes/component-library';
import { useRejectionReasonsQuery } from '../../graphql/generated';
import { Disclosure } from '@headlessui/react';

interface RejectionResons {
  activeBankruptcy: boolean;
  creditScoreTooLow: boolean;
  debtTooHigh: boolean;
  incomeTooLow: boolean;
  homePriceTooLow: boolean;
  savingsTooLow: boolean;
}

const ExpandedItem = {
  BANKRUPTCY: 'BANKRUPTCY',
  CREDIT_SCORE: 'CREDIT_SCORE',
  DEBT_PAYMENT: 'DEBT_PAYMENT',
  INCOME: 'INCOME',
  HOME_PRICE: 'HOME_PRICE',
  TOTAL_SAVINGS: 'TOTAL_SAVINGS',
};

export function RejectionReasons() {
  const { data, loading, error } = useRejectionReasonsQuery();
  const rejectionReasons = (data?.preApprovalResults?.rejectionReasons ||
    {}) as RejectionResons;

  const [expandItem, setExpandItem] = useState('');
  const {
    activeBankruptcy,
    creditScoreTooLow,
    debtTooHigh,
    incomeTooLow,
    homePriceTooLow,
    savingsTooLow,
  } = rejectionReasons;

  return (
    <AsyncHandler
      loading={loading}
      error={error}
      errorMessage="We are unable to get your pre-approval results. Please refresh the page or try again later."
    >
      <div className="flex flex-col gap-2">
        <Disclosure
          as="div"
          className="flex flex-col gap-4"
          onClick={() => {
            if (expandItem === ExpandedItem.BANKRUPTCY) {
              setExpandItem('');
            } else {
              setExpandItem(ExpandedItem.BANKRUPTCY);
            }
          }}
        >
          <Disclosure.Button className="flex flex-row gap-4 justify-between">
            <div className="flex flex-row gap-2 justify-between">
              <Icon
                glyph={
                  expandItem === ExpandedItem.BANKRUPTCY
                    ? 'chevronUp'
                    : 'chevronDown'
                }
                className="w-4 h-6"
              />
              <Text className="font-bold">Consumer proposal / Bankruptcy</Text>
            </div>
            <Icon
              glyph={activeBankruptcy ? 'xCircle' : 'checkmarkCircle'}
              className="w-6 h-6"
            />
          </Disclosure.Button>
          {expandItem === ExpandedItem.BANKRUPTCY && (
            <div className="flex flex-col gap-4 border-l border-grey-5 px-4 py-2 mb-2">
              <Text>
                All applicants need to be discharged from bankruptcy or consumer
                proposal.
              </Text>
            </div>
          )}
        </Disclosure>

        <Disclosure
          as="div"
          className="flex flex-col gap-4"
          onClick={() => {
            if (expandItem === ExpandedItem.CREDIT_SCORE) {
              setExpandItem('');
            } else {
              setExpandItem(ExpandedItem.CREDIT_SCORE);
            }
          }}
        >
          <Disclosure.Button className="flex flex-row gap-4 justify-between">
            <div className="flex flex-row gap-2 justify-between">
              <Icon
                glyph={
                  expandItem === ExpandedItem.CREDIT_SCORE
                    ? 'chevronUp'
                    : 'chevronDown'
                }
                className="w-4 h-6"
              />
              <Text className="font-bold">Credit score</Text>
            </div>
            <Icon
              glyph={creditScoreTooLow ? 'xCircle' : 'checkmarkCircle'}
              className="w-6 h-6"
            />
          </Disclosure.Button>
          {expandItem === ExpandedItem.CREDIT_SCORE && (
            <div className="flex flex-col gap-4 border-l border-grey-5 px-4 py-2 mb-2">
              <Text>
                All applicants need to meet minimum credit score of 500. Total
                debt outstanding and recent loan delinquencies will be reviewed.
              </Text>
            </div>
          )}
        </Disclosure>

        <Disclosure
          as="div"
          className="flex flex-col gap-4"
          onClick={() => {
            if (expandItem === ExpandedItem.DEBT_PAYMENT) {
              setExpandItem('');
            } else {
              setExpandItem(ExpandedItem.DEBT_PAYMENT);
            }
          }}
        >
          <Disclosure.Button className="flex flex-row gap-4 justify-between">
            <div className="flex flex-row gap-2 justify-between">
              <Icon
                glyph={
                  expandItem === ExpandedItem.DEBT_PAYMENT
                    ? 'chevronUp'
                    : 'chevronDown'
                }
                className="w-4 h-6"
              />
              <Text className="font-bold">Debt payments</Text>
            </div>
            <Icon
              glyph={debtTooHigh ? 'xCircle' : 'checkmarkCircle'}
              className="w-6 h-6"
            />
          </Disclosure.Button>
          {expandItem === ExpandedItem.DEBT_PAYMENT && (
            <div className="flex flex-col gap-4 border-l border-grey-5 px-4 py-2 mb-2">
              <Text>Max debt-to-income ratio is 50%.</Text>
            </div>
          )}
        </Disclosure>

        <Disclosure
          as="div"
          className="flex flex-col gap-4"
          onClick={() => {
            if (expandItem === ExpandedItem.INCOME) {
              setExpandItem('');
            } else {
              setExpandItem(ExpandedItem.INCOME);
            }
          }}
        >
          <Disclosure.Button className="flex flex-row gap-4 justify-between">
            <div className="flex flex-row gap-2 justify-between">
              <Icon
                glyph={
                  expandItem === ExpandedItem.INCOME
                    ? 'chevronUp'
                    : 'chevronDown'
                }
                className="w-4 h-6"
              />
              <Text className="font-bold">Income</Text>
            </div>
            <Icon
              glyph={incomeTooLow ? 'xCircle' : 'checkmarkCircle'}
              className="w-6 h-6"
            />
          </Disclosure.Button>
          {expandItem === ExpandedItem.INCOME && (
            <div className="flex flex-col gap-4 border-l border-grey-5 px-4 py-2 mb-2">
              <Text>
                Minimum annual household income is $50,000 or $4,200 monthly
              </Text>
            </div>
          )}
        </Disclosure>

        <Disclosure
          as="div"
          className="flex flex-col gap-4"
          onClick={() => {
            if (expandItem === ExpandedItem.HOME_PRICE) {
              setExpandItem('');
            } else {
              setExpandItem(ExpandedItem.HOME_PRICE);
            }
          }}
        >
          <Disclosure.Button className="flex flex-row gap-4 justify-between">
            <div className="flex flex-row gap-2 justify-between">
              <Icon
                glyph={
                  expandItem === ExpandedItem.HOME_PRICE
                    ? 'chevronUp'
                    : 'chevronDown'
                }
                className="w-4 h-6"
              />
              <Text className="font-bold">Eligible home shopping budget</Text>
            </div>
            <Icon
              glyph={homePriceTooLow ? 'xCircle' : 'checkmarkCircle'}
              className="w-6 h-6"
            />
          </Disclosure.Button>
          {expandItem === ExpandedItem.HOME_PRICE && (
            <div className="flex flex-col gap-4 border-l border-grey-5 px-4 py-2 mb-2">
              <Text>Minimum home price is $150,000</Text>
            </div>
          )}
        </Disclosure>

        <Disclosure
          as="div"
          className="flex flex-col gap-4"
          onClick={() => {
            if (expandItem === ExpandedItem.TOTAL_SAVINGS) {
              setExpandItem('');
            } else {
              setExpandItem(ExpandedItem.TOTAL_SAVINGS);
            }
          }}
        >
          <Disclosure.Button className="flex flex-row gap-4 justify-between">
            <div className="flex flex-row gap-2 justify-between">
              <Icon
                glyph={
                  expandItem === ExpandedItem.TOTAL_SAVINGS
                    ? 'chevronUp'
                    : 'chevronDown'
                }
                className="w-4 h-6"
              />
              <Text className="font-bold">Down payment savings</Text>
            </div>
            <Icon
              glyph={savingsTooLow ? 'xCircle' : 'checkmarkCircle'}
              className="w-6 h-6"
            />
          </Disclosure.Button>
          {expandItem === ExpandedItem.TOTAL_SAVINGS && (
            <div className="flex flex-col gap-4 border-l border-grey-5 px-4 py-2 mb-2">
              <Text>
                We require minimum $5,000 savings. The exact down payments will
                be evaluated and confirmed upon full approval.
              </Text>
            </div>
          )}
        </Disclosure>
      </div>
    </AsyncHandler>
  );
}
